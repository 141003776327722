import type { ICustomerSummary, ISession } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-dependency-injection';
import { computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import './account-details.scss';

@inject(Router)
export class AccountDetailsCustomElement {
    loading = false;
    client = RethinkDbAuthClient;
    account?: ICustomerSummary;
    applicationState = ApplicationState;

    async attached() {
        this.loading = true;
        this.account = await AccountBillingService.getAccount();
        requestAnimationFrame(() => {
            this.loading = false;
        });
    }

    @computedFrom('applicationState')
    protected get accountOwner() {
        return ApplicationState.dataEmail;
    }

    @computedFrom('client')
    protected get accountUser() {
        return (this.client.session as ISession).email;
    }
}
