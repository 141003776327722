import type { SqueegeeCustomer } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { SubscriptionUpdatedEvent } from '../Events/SubscriptionUpdatedEvent';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { Api } from '../Server/Api';

export class SubscriptionManagement {
    private _subscriptionUpdatedSub: Subscription;

    @computedFrom('ApplicationState.stateFlags.devMode')
    protected get devMode() {
        return ApplicationState.stateFlags.devMode;
    }

    protected currencySymbol = ApplicationState.currencySymbol();

    protected customerSummary: SqueegeeCustomer = { customerId: 'loading...', subscriptions: [] };

    public async attached() {
        new ViewResizeEvent();

        await this.refreshSubscriptions();

        this._subscriptionUpdatedSub = SubscriptionUpdatedEvent.subscribe<SubscriptionUpdatedEvent>(() => {
            this.refreshSubscriptions();
        });
    }

    protected get isConnected() {
        return Api.isConnected;
    }

    protected isLoaded = false;
    protected async refreshSubscriptions() {
        if (this.isConnected) {
            this.isLoaded = false;

            const response = await Api.get<SqueegeeCustomer>(null, '/api/subscriptions/customer');

            this.customerSummary = (response && response.data) || {
                customerId: 'Unable to load subscription details.',
                subscriptions: [],
            };

            this.isLoaded = true;
        }
    }

    public detached() {
        this._subscriptionUpdatedSub && this._subscriptionUpdatedSub.dispose();
    }
}
