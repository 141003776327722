import type { Alert } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';
import { AlertCountUpdateEvent } from './AlertCountUpdateEvent';

export class AlertService {
    public static async delete(alert: Alert) {
        await Data.delete(alert, true);
        new AlertCountUpdateEvent();
    }
}
