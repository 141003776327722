import type { StoredObject, UserRole } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import type { IStoredObjectModel } from '../../utils/StoredObjectUtils';
import StoredObjectUtils from '../../utils/StoredObjectUtils';

export class AttachmentParent {
    @bindable parent: StoredObject;
    @bindable unattach: (item: StoredObject, title: string) => void;

    @bindable onClick: (model: IStoredObjectModel) => void;

    protected allowedToDettach: Array<UserRole> = ['Owner', 'Admin', 'Creator'];
    protected allowedToViewParent: Array<UserRole> = ['Owner', 'Admin', 'Creator'];
    protected model: IStoredObjectModel;

    attached() {
        this.model = StoredObjectUtils.getUIModelForStoredObject(this.parent);
    }

    open() {
        if (this.onClick && ApplicationState.isInAnyRole(this.allowedToViewParent)) this.onClick(this.model);
    }
}
