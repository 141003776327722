// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-billing-history__title {
  flex: 1;
  display: flex;
  align-items: center;
}
.account-billing-history__title h5 {
  margin: 0;
}
.account-billing-history__filter {
  display: flex;
}
.account-billing-history__empty {
  text-align: center;
  padding: 1rem;
  font-weight: 500;
}
.account-billing-history__empty i {
  margin-bottom: 1rem;
  font-size: 32px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Views/AccountBillingHistory.scss"],"names":[],"mappings":"AACI;EACI,OAAA;EACA,aAAA;EACA,mBAAA;AAAR;AACQ;EACI,SAAA;AACZ;AAEI;EACI,aAAA;AAAR;AAEI;EACI,kBAAA;EACA,aAAA;EACA,gBAAA;AAAR;AACQ;EACI,mBAAA;EACA,eAAA;AACZ","sourcesContent":[".account-billing-history {\n    &__title {\n        flex: 1;\n        display: flex;\n        align-items: center;\n        h5 {\n            margin: 0;\n        }\n    }\n    &__filter {\n        display: flex;\n    }\n    &__empty {\n        text-align: center;\n        padding: 1rem;\n        font-weight: 500;\n        i {\n            margin-bottom: 1rem;\n            font-size: 32px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
