import type { Customer } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { CustomerService } from '../../Customers/CustomerService';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';

export class AutomaticPaymentsDialog extends CustomDialog<void> {
    protected customer?: Customer;
    private _dataChangedEvent?: Subscription;

    constructor(public customerId: string) {
        super('automaticPaymentsDialog', '../AutomaticPayments/Dialogs/AutomaticPaymentsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'automatic-payments-dialog no-nav-shadow',
            isSecondaryView: true,
        });
    }

    async init() {
        this.loadCustomer();

        this._dataChangedEvent = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.updatedObjects[this.customerId] === undefined) return;
            if (e.updatedObjects[this.customerId] === null) return super.cancel();

            this.loadCustomer();
        });
    }

    private loadCustomer() {
        this.customer = CustomerService.getCustomer(this.customerId);
    }

    async getResult() {
        if (this._dataChangedEvent) this._dataChangedEvent.dispose();
    }
}
