import type { Alert, IRelatedAction, IRelatedItem, ISqueegeeClient, TranslationKey } from '@nexdynamic/squeegee-common';
import { CustomDialog } from '../Dialogs/CustomDialog';
import type { Subscription } from '../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Utilities } from '../Utilities';
import { AlertService } from './AlertService';
import { Alerts } from './Alerts';

export class AlertDetailsDialog extends CustomDialog<void> {
    deleteAlertSubscription: Subscription;
    constructor(protected alert: Alert) {
        super('AlertDetailsDialog', '../Alerts/AlertDetailsDialog.html', '', {
            cssClass: 'alert-dialog',
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
        });
    }

    public async init() {
        this.deleteAlertSubscription = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.updatedObjects[this.alert._id]?._deleted === true) {
                this.cancel();
            }

        });
    }

    public async dispose() {
        super.dispose();
        if (this.deleteAlertSubscription) {
            this.deleteAlertSubscription.dispose();
        }
    }

    protected openRelatedItem = (relatedItem: IRelatedItem) => {
        return Alerts.openRelatedItem(relatedItem);
    }

    protected runAction = (alert: Alert, relatedAction: IRelatedAction) => {
        try {
            if (/https?:\/\//.test(relatedAction.action)) {
                window.open(relatedAction.action, '_blank');
            } else {
                const action = Utilities.parseFunction<(sq: ISqueegeeClient, alert: Alert) => void>(relatedAction.action);
                action(window.sq as ISqueegeeClient, alert);
            }
        } catch (error) {
            Logger.error('Action alert failed', { alert, error });
            new NotifyUserMessage('alerts.action-failed')

        }
    }

    protected clearAlertLabel: TranslationKey = 'alerts.clear-alert';

    protected deleteAlertDelegate = () => {
        AlertService.delete(this.alert);
        this.ok();
    }

    public ok() {
        return super.ok();
    }
}
