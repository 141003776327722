import type {
    AutomaticPaymentTransactionSubType,
    Customer,
    ICustomerPaymentProvidersData,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { Utilities } from '../Utilities';
type PaymentProvider = keyof ICustomerPaymentProvidersData | string;
export type AutomaticPaymentMethod = 'direct-debit' | 'card-payments';

export type AutomaticPaymentProvider = {
    name: PaymentProvider;
    description: string;
    available: boolean;
    status: TranslationKey;
    logo?: string;
};

export class AutomaticPaymentsService {
    static _customPaymentMethods: Record<string, string>;
    static async getDirectDebitProviders(customer?: Customer): Promise<Array<AutomaticPaymentProvider>> {
        const gcStatus = await AutomaticPaymentsService.getGoCardlessStatus(customer);
        const providers: Array<AutomaticPaymentProvider> = [
            {
                name: 'gocardless',
                description: 'GoCardless (' + gcStatus + ')',
                logo: 'images/payment-providers/gocardless-logo.svg',
                available: false,
                status: gcStatus,
            },
        ];

        return providers;
    }

    static async getCardProviders(customer?: Customer): Promise<Array<AutomaticPaymentProvider>> {
        const stripeStatus = await AutomaticPaymentsService.getStripeStatus(customer);
        const providers: Array<AutomaticPaymentProvider> = [
            {
                name: 'stripe',
                description: 'Stripe (' + stripeStatus + ')',
                logo: 'images/payment-providers/stripe-logo.svg',
                available: true,
                status: stripeStatus,
            },
        ];
        if (ApplicationState.getSetting('beta-features.dna-payments', false)) {
            const dnaStatus = await AutomaticPaymentsService.getDNAStatus(customer);
            providers.push({
                name: 'dna',
                description: 'DNA (' + dnaStatus + ')',
                logo: 'images/providers-DNA.png',
                available: true,
                status: dnaStatus,
            });
        }
        return providers;
    }

    static async availableProviders(customer?: Customer): Promise<Promise<Array<AutomaticPaymentProvider>>> {
        const providers: Array<AutomaticPaymentProvider> = [];

        providers.push(...(await AutomaticPaymentsService.getDirectDebitProviders(customer)));

        // WTF Automatic card payments are only available on advanced.
        if (ApplicationState.hasAdvancedOrAbove) providers.push(...(await AutomaticPaymentsService.getCardProviders(customer)));

        this._customPaymentMethods = ApplicationState.getSetting<Record<string, string>>('global.custom-payment-methods', {});

        for (const id in this._customPaymentMethods) {
            providers.push({ available: true, name: id, description: this._customPaymentMethods[id], status: '' as TranslationKey });
        }

        return providers;
    }

    static async updateDefaultAutomaticPaymentProvider(customer: Customer | undefined, provider: PaymentProvider | 'None'): Promise<void> {
        try {
            if (!customer) {
                switch (provider) {
                    case 'gocardless':
                        ApplicationState.account.defaultAutoPaymentCollection = 'gocardless';
                        break;
                    case 'stripe':
                        ApplicationState.account.defaultAutoPaymentCollection = 'stripe';
                        break;
                    default:
                        if (this._customPaymentMethods[provider]) {
                            ApplicationState.account.defaultAutoPaymentCollection = provider;
                        } else {
                            ApplicationState.account.defaultAutoPaymentCollection = false;
                        }
                        break;
                }
                ApplicationState.save();
                return;
            }

            if (provider) customer.automaticPaymentMethod = provider === 'None' ? undefined : provider;

            if (typeof provider !== 'string') throw new Error('Provider type was invalid');

            if (!provider || provider === 'None') {
                //Remove default from customer
                delete customer.automaticPaymentMethod;
                customer.takePaymentOnInvoiced = false;
            } else {
                customer.automaticPaymentMethod = provider;
                customer.takePaymentOnInvoiced = true;
            }

            await Data.put(customer);
        } catch (error) {
            Logger.info(`Unable to update take payment on invoice method for customer ${(customer?._id, provider, error)}`);
            throw error;
        }
    }

    static async getGoCardlessStatus(customer?: Customer): Promise<TranslationKey> {
        const localise = ApplicationState.localise;
        const oauthConnections = ApplicationState.getSetting<any>('global.oauth-connections');
        const serviceParam = { service: 'GoCardless' };
        if (!oauthConnections?.gocardless) {
            // Also check legacy
            if (!ApplicationState.account.goCardlessPublishableKey) return localise('payments.not-configured', serviceParam);
        }
        try {
            if (customer) {
                const status = customer?.paymentProviderMetaData?.gocardless?.status;

                switch (status) {
                    case 'action_required':
                        new NotifyUserMessage('notifications.gocardless-account-verification-required');
                        return localise('payments.go-cardless-action-required', serviceParam);
                    case 'active':
                        return localise('payments.direct-debit-customer-active', serviceParam);
                    case 'pending':
                        if (customer.paymentProviderMetaData?.gocardless?.secondSignatureRequired) {
                            return localise('payments.direct-debit-customer-pending-unsigned', serviceParam);
                        } else {
                            return localise('payments.direct-debit-customer-pending', serviceParam);
                        }
                    case 'invited':
                        return localise('payments.go-cardless-customer-action-required', serviceParam);
                    case 'canceled':
                        return localise('payments.direct-debit-customer-canceled', serviceParam);
                }
            }

            return localise(customer ? 'payments.awaiting-customer-mandate' : 'payments.direct-debit-connected', serviceParam);
        } catch (error) {
            new NotifyUserMessage('notifications.gocardless-account-verification-error');
            Logger.error(`Error during getGoCardlessStatus for customer`, { customer: customer, error });
        }

        return localise('payments.error-checking-status', serviceParam);
    }

    static async getDNAStatus(customer?: Customer): Promise<TranslationKey> {
        const oauthConnections = ApplicationState.getSetting<any>('global.oauth-connections');
        const serviceParam = { service: 'DNA' };
        const localise = ApplicationState.localise;
        if (!oauthConnections?.dna) {
            // Also check legacy
            return localise('payments.not-configured', serviceParam);
        }

        if (!customer) return localise('payments.account-connected', serviceParam);

        if (!customer.paymentProviderMetaData?.dna?.sourceOrMandateId || customer.paymentProviderMetaData?.stripe?.status !== 'active') {
            return localise('payments.use-latest-card-if-available', serviceParam);
        }

        return localise('payments.card-connected', {
            service: serviceParam.service,
        });
    }

    static async getStripeStatus(customer?: Customer): Promise<TranslationKey> {
        const serviceParam = { service: 'Stripe' };
        const localise = ApplicationState.localise;

        const oauthConnections = ApplicationState.getSetting<any>('global.oauth-connections');

        if (!oauthConnections?.stripe || !ApplicationState.account.stripePublishableKey) {
            return localise('payments.not-configured', serviceParam);
        }

        if (!customer) return localise('payments.account-connected', serviceParam);

        if (!customer.paymentProviderMetaData?.stripe?.sourceOrMandateId || customer.paymentProviderMetaData?.stripe?.status !== 'active') {
            return localise('payments.use-latest-card-if-available', serviceParam);
        }

        return localise('payments.card-connected', {
            service: serviceParam.service,
            last4: customer.paymentProviderMetaData?.stripe?.cardLast4 || '',
            brand: customer.paymentProviderMetaData?.stripe?.cardBrand || '',
        });
    }

    static async getInviteLink(customerId: string): Promise<string> {
        const link = `${Api.apiEndpoint}/go/a/${customerId}/`;
        return await Utilities.getShortUrl(link);
    }

    static getAutomaticPaymentTransactionTypeForCustomer(customer: Customer): AutomaticPaymentTransactionSubType {
        switch (customer.automaticPaymentMethod) {
            case 'gocardless':
                return 'auto.go-cardless';
            case 'stripe':
                return 'auto.stripe';
            case 'dna':
                return 'auto.dna';
            default:
                return customer.automaticPaymentMethod || 'auto.other';
        }
    }
}

export default AutomaticPaymentsService;
