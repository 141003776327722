import type { Customer, Notification } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import AutomaticPaymentsActions from '../../AutomaticPayments/AutomaticPaymentsActions';
import AutomaticPaymentsService from '../../AutomaticPayments/AutomaticPaymentsService';
import { Data } from '../../Data/Data';
import { GlobalFlags } from '../../GlobalFlags';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../../Notifications/SendNotificationService';
import { Api } from '../../Server/Api';
import { Utilities, openSystemBrowser } from '../../Utilities';

export class AutomaticPaymentsInvite {
    @bindable customerId: string;
    stripeInviteable: boolean;
    gcInviteable: boolean;
    customerIdChanged() {
        this.load();
    }
    protected link?: string;
    protected canInvite?: boolean;

    private _api = Api;
    @computedFrom('_api.isConnected')
    protected get online() {
        return this._api.isConnected;
    }
    async load() {
        const customer = Data.get<Customer>(this.customerId);
        if (!customer) throw new Error('No customer found in automatic payments invite load');
        this.stripeInviteable =
            !!ApplicationState.account.stripePublishableKey &&
            (customer.hideStripeFromInvites === false ||
                (customer.hideStripeFromInvites === undefined &&
                    !ApplicationState.getSetting('global.stripe.hide-from-invite', undefined)));
        this.gcInviteable =
            !!ApplicationState.account.goCardlessPublishableKey &&
            (customer.hideGocardlessFromInvites === false ||
                (customer.hideGocardlessFromInvites === undefined &&
                    !ApplicationState.getSetting('global.gocardless.hide-from-invite', undefined)));
        this.canInvite = this.stripeInviteable || this.gcInviteable;
        this.link = await AutomaticPaymentsService.getInviteLink(this.customerId);
        this.initNotifications();
    }
    private initNotifications() {
        const customer = Data.get<Customer>(this.customerId);
        if (!customer) return;

        if (
            this.gcInviteable &&
            customer.paymentProviderMetaData?.gocardless?.status !== 'active' &&
            customer.paymentProviderMetaData?.gocardless?.status !== 'pending' &&
            customer.paymentProviderMetaData?.gocardless?.inviteNotificationId
        ) {
            this.goCardlessInviteNotificationId = customer.paymentProviderMetaData?.gocardless?.inviteNotificationId;
        }

        if (
            this.stripeInviteable &&
            customer.paymentProviderMetaData?.stripe?.status !== 'active' &&
            customer.paymentProviderMetaData?.stripe?.inviteNotificationId
        ) {
            this.stripeInviteNotificationId = customer.paymentProviderMetaData?.stripe?.inviteNotificationId;
        }
    }

    async send() {
        if (!this.customerId || !this.link) return Logger.error('Unable to send invite customer undefined');

        await AutomaticPaymentsActions.sendInviteMsg(this.customerId, this.link);

        this.initNotifications();
    }

    protected async view(notificationId: string) {
        const notification = Data.get<Notification>(notificationId);
        if (!notification) return;

        await SendNotificationService.previewNotification(
            notification.type,
            typeof notification.message === 'string' ? notification.message : notification.description,
            true
        );
    }

    protected goCardlessInviteNotificationId?: string;
    protected stripeInviteNotificationId?: string;

    openLink() {
        if (this.link) {
            openSystemBrowser(this.link);
        } else {
            new NotifyUserMessage('general.unable-to-load-view')
        }
    }

    copy = () => this.link && Utilities.copyToClipboard(this.link);

    isAndroidApp = GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isHttp;
}
