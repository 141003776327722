import { bindable, bindingMode, customAttribute, inject, TaskQueue } from 'aurelia-framework';
import { Utilities } from '../Utilities';

@customAttribute('load-more')
@inject(Element, TaskQueue)
export class LoadMore {
    /**
     * Which direction should we check the viewport for the last item reaching the edge
     * @type {('up' | 'down')}
     */
    @bindable({ defaultBindingMode: bindingMode.oneTime }) protected direction: 'up' | 'down' = 'down';

    @bindable protected load?: () => void;

    constructor(private element: HTMLElement) {}

    private scrolled = () => {
        console.log('Scrolling finshed');
        const isAtBottom = Math.abs(this.element.scrollTop + this.element.clientHeight - this.element.scrollHeight) < 1;
        const isAtTop = this.element.scrollTop === 0;

        if (this.direction === 'up' && isAtTop && this.load) this.load();
        else if (this.direction === 'down' && isAtBottom && this.load) this.load();
    };

    private onScroll = Utilities.debounce(this.scrolled, 250);

    protected attached() {
        this.element.addEventListener('scroll', this.onScroll);
    }

    protected detached() {
        this.element.removeEventListener('scroll', this.onScroll);
    }
}
