import type { ICustomerPaymentProvidersData } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import type { AutomaticPaymentProvider } from '../../AutomaticPayments/AutomaticPaymentsService';
import AutomaticPaymentsService from '../../AutomaticPayments/AutomaticPaymentsService';
import { Logger } from '../../Logger';
import './default-automatic-payment-provider.scss';

export class CardChargeProvider {
    protected value: '' | keyof ICustomerPaymentProvidersData | string;
    protected providers: undefined | Array<AutomaticPaymentProvider>;
    protected loading: boolean;
    async init() {
        const providers = await AutomaticPaymentsService.getCardProviders();
        const setting = ApplicationState.getSetting<string>('global.card-charging-provider', '');
        this.value = setting;
        Logger.info('CardChargeProvider', setting);
        this.providers = providers;
    }

    async attached() {
        this.loading = true;
        await this.init();
        this.loading = false;
    }

    onChange(event: any) {
        const provider = event?.target?.value;
        ApplicationState.setSetting('global.card-charging-provider', provider);
    }
}
