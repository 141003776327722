import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import type { PaymentDetails } from '../../Account/AccountBillingService';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { GlobalFlags } from '../../GlobalFlags';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import './payment-details.scss';

@inject(Router)
export class PaymentDetailsCustomElement {
    paymentDetails?: PaymentDetails;
    account?: ICustomerSummary;
    loading = false;

    constructor(private router: Router) { }

    async attached() {
        this.loading = true;
        this.account = await AccountBillingService.getAccount();
        this.paymentDetails = await AccountBillingService.getPaymentDetails();
        requestAnimationFrame(() => {
            this.loading = false;
        });
    }

    protected appleCardUpdateUrl = (() => {
        if (!GlobalFlags.isAppleMobileApp) return;

        if (!RethinkDbAuthClient.session) return '';
        const { key, value } = RethinkDbAuthClient.session;
        return `${Api.apiEndpoint}/account/billing?session-key=${key}&session-value=${value}&dataEmail=${ApplicationState.dataEmail}`
    })();

    edit() {
        this.router.navigate('/account/billing');
    }

    @computedFrom('paymentDetails.card', 'paymentDetails.card.exp_year')
    get card() {
        return this.paymentDetails?.card;
    }

    @computedFrom('account')
    get nextBillDate() {
        const sub = this.account?.subscriptions ? this.account.subscriptions[0] : undefined;
        if (!sub?.nextInvoiceDate) return;
        return ApplicationState.localise('account.next-billing-date', { date: sub.nextInvoiceDate });
    }
}
