import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { AccountBillingService } from '../../Account/AccountBillingService';
import type { Crumb } from '../../Components/Breadcrumb/breadcrumb';
import { Api } from '../../Server/Api';

export class AccountBilling {
    crumbs: Array<Crumb> = [
        { text: 'route-config.account-details-title', href: '/account' },
        { text: 'route-config.account-billing-title', href: '/account/billing' },
    ];

    account?: ICustomerSummary;
    loading = true;
    online: boolean;
    async bind() {
        this.loading = true;
        this.account = await AccountBillingService.getAccount();

        requestAnimationFrame(() => {
            this.loading = false;
        });
        this.online = Api.isConnected;
    }
}
