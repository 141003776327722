import type { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { bindable, inject } from 'aurelia-framework';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { StripeUtils } from '../../Stripe/StripeUtils';
import './SqueegeeSubscriptionPaymentCustomElement.scss';

@inject(Element)
export class SqueegeeSubscriptionPaymentCustomElement {
    _cardElement: StripeCardElement;
    private _stripe?: Stripe;
    @bindable done?: (ok: boolean) => void;
    @bindable isSqueegeePayment: boolean;
    errorMessage: string;
    displaySuccess: boolean;
    loading = true;
    clientSecret?: string;
    constructor(private _element: Element) { }

    async bind() {
        try {
            const url = '/api/new-stripe-payment-setup?isSqueegeePayment=true';
            const result = await Api.get<{ client_secret: string }>(null, url);

            this.clientSecret = result?.data.client_secret;
        } catch (error) {
            Logger.error('Unable to get stripe client secret');
        }
    }

    async attached() {
        this.loading = true;

        this._stripe = await StripeUtils.squeegeeStripe();

        if (this._stripe) {
            const elements = this._stripe.elements();
            this._cardElement = elements.create('card', {
                hidePostalCode: false,
                style: {
                    base: {
                        fontWeight: '500',
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '16px',
                    },
                },
            });

            const element = this._element.querySelector<HTMLElement>('#stripe-container');
            if (element) {
                this._cardElement.mount(element);
                this._cardElement.on('change', event => {
                    if (event && event.error) {
                        this.errorMessage = event.error.message || '';
                    } else {
                        this.errorMessage = '';
                    }
                });
            }
        }

        this.loading = false;
    }
    async confirmCardPayment() {
        if (!this._stripe || !this.clientSecret) return;
        new LoaderEvent(true, true, 'loader.updating-payment-details');
        try {
            const cardSetup = await this._stripe.confirmCardSetup(this.clientSecret, {
                payment_method: {
                    card: this._cardElement,
                },
            });

            if (cardSetup.error) {
                // Display error.message in your UI.
                this.errorMessage = cardSetup.error.message || '';
                this.done && this.done(false);
            } else {
                if (cardSetup.setupIntent && cardSetup.setupIntent.status === 'succeeded') {
                    this.errorMessage = '';
                    new NotifyUserMessage('card.details-saved-notification');
                    this.done && this.done(true);
                }
            }
        } finally {
            new LoaderEvent(false);
        }
    }
}
