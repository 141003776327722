import type { AuditSubject, StoredObject } from '@nexdynamic/squeegee-common';
import { AuditEvent } from '@nexdynamic/squeegee-common';
import type { Dexie, Table } from 'dexie';
import dexie from 'dexie';
import { ApplicationState } from './ApplicationState';
import { Logger } from './Logger';
import { Api } from './Server/Api';

export class AuditManager {
    private static _initialising: Promise<Dexie & { events: Table<AuditEvent, string> }> | null = null;
    private static _auditDb: Dexie & { events: Table<AuditEvent, string> };

    public static async initialise(): Promise<Dexie & { events: Table<AuditEvent, string> }> {
        if (AuditManager._initialising) return AuditManager._initialising;

        AuditManager._initialising = new Promise(async (resolve, reject) => {
            try {
                AuditManager._auditDb = <Dexie & { events: Table<AuditEvent, string> }>(
                    new dexie('squegee_audit', { chromeTransactionDurability: 'relaxed' })
                );
                AuditManager._auditDb.version(1).stores({ events: '&_id' });
                await AuditManager._auditDb.open();
                resolve(AuditManager._auditDb);
            } catch (error) {
                Logger.error('Failed to initialise AuditManager', error);
                reject(error);
            }
        });

        window.sq.AuditManager = AuditManager;

        return AuditManager._initialising;
    }

    public static async recordAuditEvent({
        action,
        items = [],
        actionDetailsDescription,
        actionDetailsSummary,
    }: {
        action: string;
        items?: Array<StoredObject>;
        actionDetailsDescription?: string;
        actionDetailsSummary?: string;
    }) {
        // if (!items?.length) return;
        // I've allowed for no items to be passed in as we may want to log an event that doesn't relate to a specific item
        // can be removed if we decide we always want to log an event against an item
        const auditData = await AuditManager.initialise();
        if (!auditData) return;

        const subject: AuditSubject = items.length ? [items[0]._id, items[0].resourceType] : ['system', 'auditevents'];
        const relatedSubjects: Array<AuditSubject> | undefined =
            items.length > 1 ? items.slice(1).map(x => [x._id, x.resourceType]) : undefined;
        const auditEvent = new AuditEvent({
            subject,
            action,
            appVersion: ApplicationState.version,
            deviceDescription: ApplicationState.deviceDescription,
            relatedSubjects,
            actionDetailsDescription,
            actionDetailsSummary,
        });

        auditEvent.ownerEmail = ApplicationState.dataEmail;
        auditEvent.createdOnDevice = ApplicationState.deviceId;

        await auditData.events.add(auditEvent);

        AuditManager.initAuditEventSender();
    }

    private static _auditEventSenderTimeout: any;
    public static async initAuditEventSender() {
        const auditData = await AuditManager.initialise();
        if (!auditData) return;

        clearTimeout(AuditManager._auditEventSenderTimeout);

        const sendAuditEvents = async () => {
            try {
                // if (!Api.isConnected || window.location.hostname === 'localhost') return;
                // I've enabled audits to run locally for testing.  Can be disabled by uncommenting the above line and commenting out the line below
                if (!Api.isConnected) return;
                const events = await auditData.events.toArray();
                const submittedEventIds = await Promise.all(events.map(event => submitEvent(event)));
                const submittedEventIdsSuccess = submittedEventIds.filter(id => id !== null) as string[];
                Logger.info(`Logged ${submittedEventIdsSuccess.length}/${events.length} audit events with Squeegee.`);
                if (submittedEventIdsSuccess.length === 0) return;
                await auditData.events
                    .bulkDelete(submittedEventIdsSuccess)
                    .catch(error => Logger.error('Failed to delete audit events', error));
            } catch (error) {
                Logger.info('Error sending audit events', error);
            } finally {
                AuditManager._auditEventSenderTimeout = setTimeout(sendAuditEvents, 15000);
            }
        };

        sendAuditEvents();
    }
}
// have put this in a promise due to dexie errors about not being ready to delete items
// https://dexie.org/docs/DexieErrors/Dexie.PrematureCommitError.html
// now seems to be able to do the bulk delete successfully after awaiting our api call to submit the events
const submitEvent = async (event: AuditEvent): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        Api.V2.submitAuditEvent(event)
            .then(success => {
                if (success) {
                    resolve(event._id);
                }
            })
            .catch(error => {
                Logger.error('Failed to submit audit event', error);
                reject(null);
            });
    });
};

export const recordDatabaseUsage = (count: number) => {
    AuditManager.recordAuditEvent({
        action: 'DatabaseUsage',
        actionDetailsDescription: `Database has ${count} records`,
        actionDetailsSummary: `Database has ${count} records`,
        items: [],
    });
};
