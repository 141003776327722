
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { TwoFactorAuthConfig } from './TwoFactorAuthConfig';

export const configureTwoFactorAuth = async () => {
    try {
        new LoaderEvent(false);
        const aureliaReactComponentDialog = new AureliaReactComponentDialog<void>({
            dialogTitle: 'two-factor.dialog-title',
            isSecondaryView: true,
            component: TwoFactorAuthConfig,
        });
        await aureliaReactComponentDialog.show();
    } catch {
        Logger.error('Error in two factor config dialog');
    }

};
