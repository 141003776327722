import { ApplicationState } from "../../ApplicationState";
import { DialogAnimation } from "../../Dialogs/DialogAnimation";
import { SmsSettingsDialog } from "../../Dialogs/SmsSettings/SmsSettingsDialog";
import { NotifyUserMessage } from "../../Notifications/NotifyUserMessage";

export const presentSmsSettings = async () => {
    if (!ApplicationState.isVerifiedForMessaging) {
        new NotifyUserMessage('general.confirm-email-feature');
        return;
    }
    const smsFromPrivateNumber = ApplicationState.getSetting('global.sms-from-private-number', '');
    const smsNumberCallRedirect = ApplicationState.getSetting('global.sms-number-call-redirect', '');

    const account = ApplicationState.account;

    const dialog = new SmsSettingsDialog(
        account.smsAPIKey,
        account.smsAPIToken,
        account.smsFromNumber,
        smsFromPrivateNumber,
        smsNumberCallRedirect,
        account.smsAPI,
        account.smsAutoTopup,
        account.smsAutoTopupTrigger,
        account.smsAutoTopupAmount,
        account.smsMobilePhonePrefixes
    );

    const result = await dialog.show(DialogAnimation.SLIDE);
    if (!dialog.cancelled) {
        ApplicationState.account.smsAPI = result.smsAPI ? result.smsAPI : <any>null;
        ApplicationState.account.smsAPIKey = result.smsAPI ? result.smsAPIKey : <any>null;
        ApplicationState.account.smsAPIToken = result.smsAPI ? result.smsAPIToken : <any>null;
        ApplicationState.account.smsFromNumber = result.smsAPI ? result.smsFromNumber : <any>null;
        ApplicationState.account.smsAutoTopup = result.smsAPI ? result.smsAutoTopup : <any>null;
        ApplicationState.account.smsAutoTopupTrigger = result.smsAPI ? result.smsAutoTopupTrigger : <any>null;
        ApplicationState.account.smsAutoTopupAmount = result.smsAPI ? result.smsAutoTopupAmount : <any>null;
        ApplicationState.account.provisionalFromName = result.smsAPI ? result.provisionalFromName : <any>null;
        ApplicationState.account.provisionalFromNameRequestedTimestamp = Date.now();

        ApplicationState.account.smsMobilePhonePrefixes = result.smsAPI ? result.smsMobilePhonePrefixes : <any>null;
        delete ApplicationState._mobilePrefixes;

        ApplicationState.save();

        ApplicationState.setSetting('global.sms-number-call-redirect', result.smsAPI ? result.smsNumberCallRedirect : '');
        ApplicationState.setSetting('global.sms-from-private-number', result.smsAPI ? result.smsFromPrivateNumber : '');
    }

}
