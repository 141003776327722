import type { ICustomerSubscriptionSummary, InvoiceTransactionSummary, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SubscriptionApi } from '../Server/SubscriptionApi';
import { openSystemBrowser } from '../Utilities';
import { getRedirectOnSuccessUrl } from '../getRedirectOnSuccessUrl';

export class AccountActions {
    public static async cancelSubscription(subscription: ICustomerSubscriptionSummary) {
        const switchPrompt = new Prompt('general.cancel-plan', 'cancel.subscription-description-and-warning', {
            okLabel: 'general.cancel-plan',
            cancelLabel: 'global.back',
        });

        await switchPrompt.show();
        if (switchPrompt.cancelled) return;

        try {
            new LoaderEvent(true);
            const sub = await SubscriptionApi.cancelSubscription(subscription.id);
            if (!sub) throw 'Failed to get updated subscription details.';
            const redirect = getRedirectOnSuccessUrl();
            if (redirect) {
                window.location.href = redirect;
            }
        } catch (error) {
            Logger.error('Error during switch plans', error);
            new Prompt('general.warning', 'failed.to-cancel');
        } finally {
            new LoaderEvent(false);
        }
    }

    public static async processAccountDelete() {
        if (ApplicationState.dataEmail !== RethinkDbAuthClient.session?.email) {
            return void new Prompt('general.error', 'delete.account-owner-only', { cancelLabel: '' });
        }

        const hInitial = ApplicationState.localise('account.delete-account-notice-heading');
        const m1Initial = ApplicationState.localise('account.delete-account-notice1');
        const m2Initial = ApplicationState.localise('account.delete-account-notice2');
        const descriptionInitial =
            `<div><h3>${hInitial}</h3>${m1Initial}<br /><br /><b>${m2Initial}</b></div>` as unknown as TranslationKey;

        if (!(await new Prompt('empty.string', descriptionInitial, { okLabel: 'general.continue', cssClass: 'delete-prompt' }).show()))
            return;

        const hConfirm = ApplicationState.localise('account.delete-account-notice-heading');
        const m1Confirm = ApplicationState.localise('delete.account-description-and-warning1');
        const m2Confirm = ApplicationState.localise('delete.account-description-and-warning2');

        const descriptionConfirm =
            `<div class="alarm-panel"><h3>${hConfirm}</h3>${m1Confirm}<br /><br /><b>${m2Confirm}</b></div>` as unknown as TranslationKey;

        const switchPrompt = new Prompt('empty.string', descriptionConfirm, {
            okLabel: 'delete.account-title',
            cancelLabel: 'general.cancel',
            cssClass: 'delete-prompt',
        });

        await switchPrompt.show();
        if (switchPrompt.cancelled) return false;

        let success = false;
        try {
            new LoaderEvent(true);
            const result = await SubscriptionApi.deleteAccount(ApplicationState.dataEmail);
            if (result) success = true;
        } catch (error) {
            Logger.error('Failed to submit account delete request', error);
            success = false;
        } finally {
            new LoaderEvent(false);
        }

        if (!success) return void new Prompt('general.error', 'account.delete-error', { cancelLabel: '' }).show();

        RethinkDbAuthClient.setSession();
        await new Prompt('general.complete', 'account.delete-processed', { cancelLabel: '' }).show();
        ApplicationState.signOut(false);
    }

    public static async subscribe(
        planId: string,
        quantity: number,
        hasSubscription = false,
        verifyPayment = true,
        confirm = true,
        failOnExisting = false
    ): Promise<{ success: boolean }> {
        try {
            let cont = !confirm;

            if (confirm) {
                const prompt = new Prompt('general.confirm', hasSubscription ? 'account.subscribe-existing' : 'account.subscribe-new', {
                    okLabel: 'general.confirm',
                });

                await prompt.show();

                cont = !prompt.cancelled;
            }

            if (cont) {
                new LoaderEvent(true);
                const subscription = await SubscriptionApi.customerSubscribe(planId, quantity, undefined, verifyPayment, failOnExisting);
                const balanceCheck = await ApplicationState.updateAccountBalance();

                if ((balanceCheck?.balance || 0) > 0) await AccountActions.payBalance();

                await ApplicationState.updateSubscription(subscription);
                hasSubscription
                    ? new NotifyUserMessage('account.subscription-updated')
                    : new NotifyUserMessage('account.subscription-started');

                return { success: true };
            }

            return { success: false };
        } catch (error) {
            Logger.error('Unable to subscribe', error);
            const message: TranslationKey =
                typeof error === 'string' ? (error as TranslationKey) : 'account.subscription-change-error-unknown';

            new LoaderEvent(false);
            await new Prompt('account.subscription-change-error', message, { cancelLabel: '' }).show();

            return { success: false };
        } finally {
            new LoaderEvent(false);
        }
    }

    public static async reactivatePlan(subscriptionId: string) {
        try {
            const prompt = new Prompt('account.reactivate-plan', 'account.reactivate-plan-description', { okLabel: 'account.reactivate' });

            await prompt.show();

            if (!prompt.cancelled) {
                new LoaderEvent(true);
                const subscription = await SubscriptionApi.reactivateSubscription(subscriptionId);
                if (!subscription) throw new Error('Subscription not reactivated');
                const redirect = getRedirectOnSuccessUrl();
                if (redirect) {
                    window.location.href = redirect;
                }
                new NotifyUserMessage('account.reactivate-complete');
            }
        } catch (error) {
            Logger.error('Unable to reactivate current plan', error);
        } finally {
            new LoaderEvent(false);
        }
    }

    public static async payBalance() {
        try {
            const balanceCheck = ApplicationState.accountBalance?.balance || 0;
            if (balanceCheck <= 0) return;

            const balance = Math.abs(balanceCheck).toString();
            const currencySymbol = ApplicationState.currencySymbol();
            const paid = await SubscriptionApi.payBalance();
            if (paid) new NotifyUserMessage('account.pay-balance-complete', { balance, currencySymbol });
            else new NotifyUserMessage('account.pay-balance-failed', { balance, currencySymbol });
            return paid;
        } catch (error) {
            Logger.error(`Unable to pay balance of `, error);
        }
    }

    public static viewInvoiceSummary(invoice: InvoiceTransactionSummary) {
        try {
            const url = `${Api.apiEndpoint}/api/user/invoice/${ApplicationState.dataEmail}/${invoice._id}`;
            openSystemBrowser(url);
        } catch (error) {
            Logger.error(`Unable to download invoice ${invoice.invoiceNumber}`, error);
        }
    }
}
