import type { InvoiceTransactionSummary } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import moment from 'moment';
import { AccountBillingService } from '../../Account/AccountBillingService';
import './account-invoices.scss';
import './payment-details.scss';

@inject(Router)
export class AccountInvoicesCustomElement {
    constructor(private router: Router) { }
    invoiceSummaries?: Array<InvoiceTransactionSummary> = [];
    loading = true;
    async attached() {
        this.loading = true;
        const start = moment().startOf('y');
        const invoices = await AccountBillingService.getInvoiceSummaries({ limit: 4, startDate: start.toISOString() });
        this.invoiceSummaries = invoices ? invoices?.slice(0, 4) : [];
        this.loading = false;
    }

    billingHistory() {
        this.router.navigate('/account/billing-history');
    }
}
