import type {
    AlertType,
    Customer,
    IRelatedItem,
    ISqueegeeClient,
    JobOccurrence,
    Notification,
    Quote,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { Alert, JobOccurrenceStatus, SyncMode, TransactionType, randomInteger, sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { CustomerDialog, CustomerDialogTab } from '../Customers/Components/CustomerDialog';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { prompt } from '../Dialogs/ReactDialogProvider';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { MenuBarActionsEvent } from '../Events/MenuBarActionsEvent';
import { GlobalFlags } from '../GlobalFlags';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { QuoteSummaryDialog } from '../Quotes/Dialogs/QuoteSummaryDialog';
import { ScheduleDetailsDialog } from '../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { TransactionDialog } from '../Transactions/Components/TransactionDialog';
import { UserService } from '../Users/UserService';
import { animate } from '../Utilities';
import { isDevMode } from '../isDevMode';
import { t } from '../t';
import { AlertCountUpdateEvent } from './AlertCountUpdateEvent';
import { AlertDetailsDialog } from './AlertDetailsDialog';
import { AlertService } from './AlertService';

export class Alerts {
    protected error?: string;
    protected showRead = false;
    protected alertTypes: Array<string>;
    protected myAlerts: Array<Alert>;
    protected alertCounts = {} as { [type: string]: number };
    protected filteredAlerts: Array<Alert>;
    protected isDevMode = isDevMode();

    protected alertId?: string;
    private _dataRefreshSubscription: Subscription;
    public async attached() {
        try {
            this.filterAlerts();
            this._dataRefreshSubscription = DataRefreshedEvent.subscribe<DataRefreshedEvent>(e => {
                if (!e.hasAnyType('alerts')) return;

                return this.filterAlerts();
            });

            const alertId = this.alertId;
            if (alertId) {
                const alert = Data.get<Alert>(alertId);
                if (alert) this.displayAlert(alert);
                else {
                    Data.fullSync(SyncMode.Partial)
                        .catch()
                        .then(() => {
                            const alert = Data.get<Alert>(alertId);
                            if (!alert) return;

                            this.displayAlert(alert);
                        });
                }
            }
        } catch (error) {
            Logger.error('Unable to load alerts', error);
            this.error = 'Currently unable to load the alerts, please email support@squeeg.ee if you have a support issue';
        }
    }
    displayAlert(alert: Alert) {
        if (alert) {
            new AlertDetailsDialog(alert).show(DialogAnimation.SLIDE_UP);
        } else {
            prompt('alerts.not-found-title', 'alerts.not-found-description', { cancelLabel: '' }).show();
        }
    }

    activate(params?: { alertId?: string }) {
        this.alertId = params?.alertId;
    }

    public async detached() {
        this._dataRefreshSubscription && this._dataRefreshSubscription.dispose();
    }

    private async filterAlerts(): Promise<void> {
        const email = RethinkDbAuthClient.session?.email;
        if (!email) return;

        const isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);

        // Grab all the alerts.
        this.myAlerts = Data.all<Alert>('alerts', a => {
            if (a.audience?.length && a.audience.includes(email)) return true;
            if (!a.audience?.length && isOwnerAdminOrCreator) return true;
            return false;
        })
            .slice()
            .sort(sortByCreatedDateDesc);

        this.filteredAlerts = [] as Array<Alert>;

        await animate();

        const alertCounts = {} as Record<string, number>;

        const alertTypes = new Array<AlertType>();

        for (const alert of this.myAlerts) {
            // Not an admin and not in the audience
            if (!isOwnerAdminOrCreator && !alert.audience?.includes(email)) continue;

            // Is an admin, there is an audience and the user is not in the audience.
            if (isOwnerAdminOrCreator && alert.audience?.length && !alert.audience.includes(email)) continue;

            // Add this type to the tab.
            if (!alertTypes.includes(alert.type as AlertType)) alertTypes.push(alert.type as AlertType);

            // Increase this alert type count.
            alertCounts[alert.type] = (alertCounts[alert.type] || 0) + 1;

            if (!this.activeTab) this.activeTab = alertTypes[0];

            // Not looking at this tab.
            if (alert.type !== this.activeTab) continue;

            this.filteredAlerts.push(alert);
        }

        if (this.activeTab && !this.filteredAlerts.length) {
            this.activeTab = '';
            return this.filterAlerts();
        }

        this.alertTypes = alertTypes;
        this.alertCounts = alertCounts;

        this.createMenu();
    }

    protected alertTypeIcon(type: AlertType): [name: TranslationKey, icon: string] {
        const empty = t('empty.string');
        switch (type) {
            case 'appointment':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.appointment'), 'event'];
            case 'bugs':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.bugs'), 'bug_report'];
            case 'customer':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.customer'), 'person'];
            case 'sms':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.sms'), 'sms'];
            case 'email':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.email'), 'email'];
            case 'payment':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.payment'), 'payment'];
            case 'jobOccurrence':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.jobOccurrence'), 'today'];
            case 'quote':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.quote'), 'description'];
            case 'lost':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.lost'), 'cancel'];
            case 'message':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.message'), 'forum'];
            case 'squeegee':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.squeegee'), 'mail_lock'];
            case 'subs':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.subs'), 'bookmark_add'];
            case 'system':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.system'), 'admin_panel_settings'];
            case 'portal':
                return [GlobalFlags.isMobile ? empty : t('alert-labels.portal'), 'public'];
            default:
                return [empty, ''];
        }
    }

    protected createMenu() {
        const menuActions = [] as Array<IMenuBarAction>;
        if (this.filteredAlerts.length) {
            const isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);

            if (isOwnerAdminOrCreator) {
                menuActions.push({
                    icon: 'clear_all',
                    handler: () => this._deleteAllAlerts(),
                    tooltip: 'clear.all-alerts',
                    roles: ['Admin', 'Owner', 'Creator', 'Planner'],
                });
            }
            if (this.activeTab && (!isOwnerAdminOrCreator || this.alertTypes.length > 1)) {
                menuActions.push({
                    icon: 'clear_all',
                    handler: () => this._deleteActiveTabAlerts(),
                    tooltip: ApplicationState.localise('clear.all-alert-of-type', {
                        type: ApplicationState.localise(`alert-type.${this.activeTab}`),
                    }),
                    roles: ['Admin', 'Owner', 'Creator', 'Planner'],
                });
            }
        }

        new MenuBarActionsEvent(menuActions.length ? menuActions : undefined);
    }

    protected activeTab: AlertType | '';

    protected switchTab(type: AlertType) {
        this.activeTab = type;
        this.filterAlerts();
    }

    protected openRelatedItem = (relatedItem: IRelatedItem) => {
        return Alerts.openRelatedItem(relatedItem);
    };

    static openRelatedItem(relatedItem: IRelatedItem) {
        const relatedItemObject = Data.get(relatedItem.id);
        if (!relatedItemObject) return new NotifyUserMessage('error.unable-to-view-item-on-this-device', { name: relatedItem.name });

        switch (relatedItemObject.resourceType) {
            case 'customers':
                return new CustomerDialog(relatedItemObject as Customer).show();
            case 'notifications': {
                const notification = relatedItemObject as Notification;
                const notificationCustomer = Data.get<Customer>(notification.customerId || notification.addressee);
                if (!notificationCustomer) break;

                return new CustomerDialog(notificationCustomer, CustomerDialogTab.MESSAGES).show();
            }
            case 'transactions': {
                const transaction = relatedItemObject as Transaction;
                return new TransactionDialog(transaction).show();
            }
            case 'joboccurrences': {
                const occurrence = relatedItemObject as JobOccurrence;

                const job = JobService.getJob(occurrence.customerId, occurrence.jobId);
                if (!job) break;

                const occurrenceCustomer = Data.get<Customer>(job.customerId);
                if (!occurrenceCustomer) break;

                const scheduleItem = new ScheduleItem(job, occurrenceCustomer, occurrence);
                return new ScheduleDetailsDialog(scheduleItem).show();
            }

            case 'quotes': {
                new QuoteSummaryDialog(relatedItemObject as Quote).show();
                return;
            }
        }

        return new NotifyUserMessage('error.unable-to-view-item-on-this-device', { name: relatedItem.name });
    }

    private _deleteAllAlerts = async () => {
        const numberedAlertsString = this.myAlerts.length.toString();
        const title = 'clear.all-alerts';
        const description = ApplicationState.localise('clear.all-alerts-numbered', { numberAlerts: numberedAlertsString });
        const goAheadPrompt = prompt(title, description, { okLabel: 'general.clear-all' });
        const goAhead = await goAheadPrompt.show();

        if (!goAhead) return;

        await Data.delete(this.myAlerts, true);

        new AlertCountUpdateEvent();

        return this.filterAlerts();
    };

    private _deleteActiveTabAlerts = async () => {
        const filteredAlertsString = this.filteredAlerts.length.toString();
        const title = ApplicationState.localise('clear.filtered-alerts', { filteredAlerts: filteredAlertsString, tab: this.activeTab });
        const description = ApplicationState.localise('clear.filtered-alert-description', {
            filteredAlerts: filteredAlertsString,
            tab: this.activeTab,
        });
        const goAheadPrompt = prompt(title, description, { okLabel: 'general.clear-alerts' });
        const goAhead = await goAheadPrompt.show();

        if (!goAhead) return;

        await Data.delete(this.filteredAlerts, true);

        new AlertCountUpdateEvent();

        return this.filterAlerts();
    };

    protected deleteAlert = (_event: any, alert: Alert) => {
        AlertService.delete(alert).then(() => {
            let count = this.filteredAlerts.length;

            while (count--) {
                if (this.filteredAlerts[count]._id === alert._id) {
                    this.filteredAlerts.splice(count, 1);
                    break;
                }
            }

            this.filterAlerts();
        });

        return true;
    };

    protected openAlert = async (alert: Alert) => {
        new AlertDetailsDialog(alert).show();
    };

    protected async createSampleAlerts() {
        const types: Array<AlertType> = [
            'appointment',
            'customer',
            'sms',
            'email',
            'payment',
            'jobOccurrence',
            'quote',
            'lost',
            'message',
            'portal',
            'finance',
        ];
        const customers = Data.all<Customer>('customers');
        for (const t of types) {
            for (let i = 0, l = Math.floor(Math.random() * 5); i < l; i++) {
                const customer = customers[randomInteger(0, customers.length - 1)];
                const alert = new Alert(
                    t + ' test alert',
                    'This is a test for the ' + t + ' alert type',
                    t,
                    [
                        {
                            id: customer._id,
                            name: customer.name,
                            icon: 'person',
                        },
                    ],
                    [
                        {
                            name: 'Sample Prompt',
                            icon: 'warning',
                            action: ((sq: ISqueegeeClient) => {
                                new sq.GuiApi.Dialogs.Prompt('Testing!', 'This is a test alert.', { cancelLabel: '' }).show();
                            }).toString(),
                        },
                    ]
                );

                // create a a random date between now and 90 days ago
                const date = moment(new Date(Date.now() - Math.floor(Math.random() * 7776000000)));
                alert.createdDate = date.toISOString();

                Data.put(alert);
            }
        }

        const alerts = [] as Array<Alert>;

        const cancelledCustomers = Data.all<Customer>('customers', customer => customer.automaticPaymentMethod === 'gocardless').slice(
            0,
            5
        );

        for (const customer of cancelledCustomers) {
            alerts.push(Alert.createCancelledDirectDebitMandateAlert(customer, false));
        }

        const failedPayments = Data.all<Transaction>(
            'transactions',
            t => t.transactionType === TransactionType.AutomaticPayment && t.status === 'failed'
        ).slice(0, 5);

        for (const failedPayment of failedPayments) {
            if (failedPayment.customerId) {
                const customer = Data.get<Customer>(failedPayment.customerId);
                if (customer) {
                    alerts.push(Alert.createFailedAutomaticPaymentAlert(customer, failedPayment, ApplicationState.currencySymbol()));
                }
            }
        }

        const failedEmails = Data.all<Notification>('notifications', n => n.type === 'Email' && n.status === 'failed').slice(0, 5);

        for (const failedEmail of failedEmails) {
            if (failedEmail.addressee) {
                const customer = Data.get<Customer>(failedEmail.addressee);
                if (customer) {
                    alerts.push(Alert.createFailedEmailAlert(customer, failedEmail, 'This one failed for no reason :o)'));
                }
            }
        }

        const failedSmss = Data.all<Notification>('notifications', n => n.type === 'SMS' && n.status === 'failed').slice(0, 5);

        for (const failedSms of failedSmss) {
            if (failedSms.addressee) {
                const customer = Data.get<Customer>(failedSms.addressee);
                if (customer) {
                    alerts.push(Alert.createFailedSmsAlert(customer, failedSms, 'This one failed just to annoy you! :o)'));
                }
            }
        }

        const skippedOccurrences = Data.all<JobOccurrence>('joboccurrences', o => o.status === JobOccurrenceStatus.Skipped).slice(0, 5);

        for (const skippedOccurrence of skippedOccurrences) {
            if (!skippedOccurrence.customerId) continue;

            const customer = Data.get<Customer>(skippedOccurrence.customerId);
            if (customer) {
                const accountUser = UserService.getUser();
                const user = accountUser ? accountUser.name : 'A user';
                alerts.push(Alert.createSkippedJobAlert(customer, skippedOccurrence, user));
            }
        }

        const actionAlert = new Alert(
            'How Much Data',
            'Tell me how many data items I have in my database using an action alert.',
            'squeegee',
            [],
            [
                {
                    icon: 'send',
                    name: 'Tell Me!',
                    action: ((sq: ISqueegeeClient, alert: Alert) => {
                        const dataCountStr = sq.Data.count('').toString();
                        new sq.GuiApi.NotifyUserMessage(sq.ApplicationState.localise('alerts.items-in-db', { data: dataCountStr }));
                        sq.Data.delete([alert]);
                    }).toString(),
                },
            ]
        );

        alerts.push(actionAlert);

        await Data.put(alerts, true, 'lazy');

        new AlertCountUpdateEvent();

        return this.filterAlerts();
    }
}
