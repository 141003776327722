import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { AccountActions } from '../../Account/AccountActions';
import type { PaymentDetails } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import './account-billing-form.scss';
export class AccountBillingFormCustomElement {
    @bindable account?: ICustomerSummary;
    @bindable loading?: boolean;

    protected balance?: number;
    protected currencySymbol?: string;

    @computedFrom('applicationState.accountBalance')
    get balanceLabel() {
        return !this.balance ? '' : this.balance > 0 ? 'financials.owed-label' : 'financials.credit-label';
    }

    async attached() {
        const balanceDetails = await ApplicationState.updateAccountBalance();
        this.balance = balanceDetails?.balance;
        this.currencySymbol = balanceDetails?.currencySymbol;
    }
    protected onPaymentMethodSaved = async (paymentDetails?: PaymentDetails) => {
        if (this.balance && this.balance > 0) {
            Logger.info('Payment details updated', paymentDetails);
            new LoaderEvent(true, undefined, ApplicationState.localise('account.clearing-balance'));
            await AccountActions.payBalance();

            const balanceDetails = await ApplicationState.updateAccountBalance();
            this.balance = balanceDetails?.balance;
            this.currencySymbol = balanceDetails?.currencySymbol;

            new LoaderEvent(false);
        }
    };
}
