import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from './ApplicationState';
import { SqueegeeLocalStorage } from './Data/SqueegeeLocalStorage';
import { StateFlagsChangedEvent } from './Events/StateFlagsChangedEvent';

export interface IApplicationStateFlags {
    showDevModeCounter: number;
    enablingDevMode: boolean;
    connectionStatusText: string;
    devMode: boolean;
}

export class ApplicationStateFlags implements IApplicationStateFlags {
    private get DEV_MODE_KEY() {
        return 'devMode_' + ApplicationState.version;
    }
    private _devMode: boolean | undefined;
    @computedFrom('_devMode')
    public get devMode() {
        if (ApplicationState.stateFlags._devMode === undefined) {
            this._devMode = SqueegeeLocalStorage.getItem(this.DEV_MODE_KEY) === 'true';
        }
        return !!this._devMode;
    }
    public set devMode(show: boolean) {
        show ? SqueegeeLocalStorage.setItem(this.DEV_MODE_KEY, 'true') : SqueegeeLocalStorage.removeItem(this.DEV_MODE_KEY);
        this._devMode = show;
        ApplicationState.refreshNavigation();
        new StateFlagsChangedEvent();
    }

    private _showDevModeCounter = 20;
    public get showDevModeCounter() {
        return this._showDevModeCounter;
    }

    set showDevModeCounter(value: number) {
        this._showDevModeCounter = value;
        new StateFlagsChangedEvent();
    }

    private _enablingDevMode = false;
    get enablingDevMode() {
        return this._enablingDevMode;
    }

    set enablingDevMode(value: boolean) {
        this._enablingDevMode = value;
        new StateFlagsChangedEvent();
    }

    private _connectionStatusText = '';
    get connectionStatusText() {
        return this._connectionStatusText;
    }

    set connectionStatusText(value: string) {
        this._connectionStatusText = value;
        new StateFlagsChangedEvent();
    }
}
