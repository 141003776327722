import type { InvoiceTransactionSummary } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import type { Crumb } from '../../Components/Breadcrumb/breadcrumb';
import { Logger } from '../../Logger';
import { Api } from '../../Server/Api';
import './AccountBillingHistory.scss';

type FilterOption = {
    text: string;
    value: {
        start?: string;
        end?: string;
    };
};

export class AccountInvoices {
    crumbs: Array<Crumb> = [
        { text: 'route-config.account-details-title', href: '/account' },
        { text: 'route-config.account-invoices-title', href: '/account/billing-history' },
    ];
    applicationState = ApplicationState;

    transactions?: Array<InvoiceTransactionSummary>;

    selectedFilter?: FilterOption;
    filterOptions?: Array<FilterOption>;
    loading = true;
    online: boolean;
    error = false;

    bind() {
        this.setFilterOptions();
        this.error = false;
        this.load();
        this.online = Api.isConnected;
    }

    async load() {
        try {
            this.loading = true;
            this.transactions = await AccountBillingService.getInvoiceSummaries({
                startDate: this.selectedFilter?.value.start,
                endDate: this.selectedFilter?.value.end,
            });

            this.error = false;
        } catch (error) {
            Logger.error('Unable to load invoices view');
            this.error = true;
        } finally {
            this.loading = false;
        }
    }

    download(invoice: any) {
        console.log(invoice);
    }

    filterChanged() {
        this.load();
    }

    setFilterOptions() {
        const startOfYear = moment().startOf('year');
        const yearsToShow = 5;
        const options: Array<FilterOption> = [
            {
                text: ApplicationState.localise('account.invoices-this-year'),
                value: { start: startOfYear.toISOString(), end: startOfYear.endOf('y').toISOString() },
            },
        ];

        for (let i = 0; i < yearsToShow; i++) {
            const date = startOfYear.subtract(1, 'year');
            options.push({
                text: date.year().toString(),
                value: { start: date.startOf('y').toISOString(), end: date.endOf('y').toISOString() },
            });
        }

        const lastOption = options[options.length - 1];
        options.push({ text: ApplicationState.localise('account.invoices-archived'), value: { end: lastOption.value.start } });

        if (!this.selectedFilter) this.selectedFilter = options[0];
        this.filterOptions = options;
    }

    @computedFrom('invoices', 'selectedFilter')
    get emptyMsg() {
        if (this.selectedFilter) return ApplicationState.localise('account.invoices-empty', { year: this.selectedFilter.text });
    }

    @computedFrom('applicationState.stateFlags.devMode')
    get isDevMode() {
        return ApplicationState.stateFlags.devMode;
    }
}
