import type { AttachmentDimensions } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { Logger } from '../../Logger';
import { Api } from '../../Server/Api';
import './attachment-img-container.scss';

export class AttachmentImgContainer {
    @bindable() attachmentId: string;
    @bindable() thumbnail: string;
    @bindable() dimensions: AttachmentDimensions;
    @bindable() mimeType: string;
    @bindable() onClick: () => void;

    protected imgSrc: string | undefined;
    protected loading = false;
    protected isOnline = Api.isConnected;

    private _forceShowIcon: boolean;

    mimeTypeChanged() {
        this.loadImg();
    }

    async loadImg() {
        try {
            this.loading = true;
            if (this.attachmentId) {
                if (this.isOnline) {
                    const url = `/api/attachments/${this.attachmentId}?t=${new Date().getTime()}`;
                    const response = await Api.get<Blob>(null, url, false, 20000, false, false, { responseType: 'blob' });
                    if (!response) throw new Error('no response');
                    this.imgSrc = URL.createObjectURL(response.data);
                    this._forceShowIcon = false;
                } else if (this.thumbnail) {
                    this.imgSrc = this.thumbnail;
                    this._forceShowIcon = false;
                } else {
                    this._forceShowIcon = true;
                }
            }
        } catch (error) {
            Logger.warn('Unable to load attachment image', error);
            this.imgSrc = this.thumbnail;
        }
    }

    @computedFrom('mimeType', '_forceShowIcon')
    get isImage() {
        return !this._forceShowIcon && /image/i.test(this.mimeType);
    }

    onImageLoaded() {
        this.loading = false;
    }
}
