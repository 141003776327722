import { bindable, inject } from 'aurelia-framework';
import { Logger } from '../Logger';
import { Utilities } from '../Utilities';
import './hidden-on.scss';

const MediaSizes = {
    xs: 414,
    sm: 512,
    md: 768,
    lg: 1224,
};

const HIDDEN_CLASS = 'hidden-on--hidden';

@inject(Element)
export class HiddenOnCustomAttribute {
    @bindable({ primaryProperty: true }) size: keyof typeof MediaSizes;

    constructor(private element: Element) {
        this.element.classList.add(HIDDEN_CLASS);
    }

    toggleHidden = () => {
        const size = MediaSizes[this.size];
        if (!size) Logger.warn('Invalid Media Viewport size passed to HiddenCustomAttribute');
        else {
            const hide = window.matchMedia(`(max-width: ${size}px)`).matches;
            hide ? this.element.classList.add(HIDDEN_CLASS) : this.element.classList.remove(HIDDEN_CLASS);
        }
    };
    private handleResize = Utilities.debounce(this.toggleHidden, 150);

    protected sizeChanged() {
        this.toggleHidden();
    }

    protected attached() {
        window.addEventListener('resize', this.handleResize);
    }

    protected detached() {
        window.removeEventListener('resize', this.handleResize);
    }
}
