import type { Attachment, IFabAction, StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import { observable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import type { Filter } from '../../Filters/Filter';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { UploadItem } from '../../Upload/UploadItem';
import { AttachmentActions } from '../AttachmentActions';
import type { IAttachmentsFilterItemDictionary } from '../AttachmentFilters';
import * as AttachmentFilters from '../AttachmentFilters';
import { AttachmentService } from '../AttachmentService';
import { SelectAttachmentsDialog } from './SelectAttachmentsDialog';

export class AttachmentsDialog extends CustomDialog<any> {
    @observable protected searchText: string;
    protected searchTextChanged() {
        this.filterAndSort();
    }

    protected attachments: Array<Attachment>;
    protected filteredAttachments: Array<Attachment> = [];
    protected uploads: Array<UploadItem> = [];
    protected displayMode: 'grid' | 'list' = 'list';

    protected currentFilter: Filter<IAttachmentsFilterItemDictionary> = AttachmentFilters.filterMenu();
    private _dataRefreshedSub: Subscription;

    constructor(
        protected parent: StoredObject,
        public contextHeaderTitle: string,
        public contextHeaderIcon?: string,
        public dialogTitleOverride?: string
    ) {
        super('attachmentsDialog', '../Attachments/Dialogs/AttachmentsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            cssClass: 'details-dialog no-nav-shadow',
        });

        this.fabActions = this.getActions();
    }

    private getActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'general.upload-file',
                actionType: 'action-upload',
                handler: this._delegateAddNewAttachment,
                roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Reporting', 'Worker'],
            },
        ];

        if (ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner'])) {
            fabActions.push({
                tooltip: 'attachments.select-existing-attachment' as TranslationKey,
                actionType: 'action-new-attachment',
                handler: this._delegateAddExistingAttachments,
                roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Reporting', 'Worker'],
            });
        }

        return fabActions;
    }

    async init() {
        this.loadData();
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            (event: DataRefreshedEvent) => event.hasAnyType('linkers', 'attachments') && this.loadData()
        );
    }

    loadData() {
        this.fetchAttachments();
        this.filterAndSort();
    }

    dispose() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
        super.dispose();
    }

    private fetchAttachments() {
        this.attachments = AttachmentService.fetchAll(this.parent._id);
    }

    private concreteOccurrence() {
        if (this.parent.resourceType !== 'joboccurrences') return;
        if (Data.get(this.parent._id)) return;

        Data.put(this.parent);
        Logger.info(`Concreted occurrence ${this.parent._id} due to attachment change`);
    }

    private _delegateAddNewAttachment = async () => {
        const successful = await AttachmentActions.addNew(this.parent);
        if (successful) this.concreteOccurrence();
    };

    private _delegateAddExistingAttachments = async () => {
        const dialog = new SelectAttachmentsDialog(this.attachments);
        const attachments = await dialog.show();

        if (dialog.cancelled) return;
        if (attachments && attachments.length) {
            for (const attachment of attachments) {
                await AttachmentService.linkAttachment(attachment, this.parent);
            }

            this.attachments.push(...attachments);
            this.filterAndSort();
            this.concreteOccurrence();
        }
    };

    protected async removeAttachment(attachment: Attachment) {
        try {
            const prompt = new Prompt('attachments.confirm-remove', `attachments.confirm-remove-attachment-description`, {
                okLabel: 'general.remove',
                localisationParams: {
                    attachmentName: attachment.name,
                    context: this.contextHeaderTitle,
                },
            });

            await prompt.show();

            if (!prompt.cancelled) {
                await AttachmentService.remove(this.parent._id, attachment);
                // if this attachment is not attached to any other resources then offer to delete the attachment permanently
                if (!AttachmentService.isAttached(attachment._id)) {
                    const dialog = new Prompt('attachments.permanently-delete', `attachments.permanently-delete-description`, {
                        okLabel: 'general.delete',
                        cancelLabel: 'Keep' as TranslationKey,
                        localisationParams: {
                            attachmentName: attachment.name,
                        },
                    });

                    await dialog.show();

                    if (!dialog.cancelled) {
                        await AttachmentService.delete(attachment);
                    }
                }

                const removeIndex = this.attachments.findIndex(a => a._id === attachment._id);
                this.attachments.splice(removeIndex, 1);
                this.filterAndSort();
            }
        } catch (error) {
            new NotifyUserMessage('action.remove-attachment-from-error', { name: attachment.name, attachedTo: this.contextHeaderTitle });
        }
    }
    protected viewDetails(attachment: Attachment) {
        AttachmentActions.viewDetails(attachment);
    }
    private filterAndSort() {
        AttachmentFilters.filterList(this.attachments, this.filteredAttachments, this.searchText, this.currentFilter);
        this.filteredAttachments = AttachmentFilters.sortList(this.filteredAttachments, this.currentFilter);
    }
    protected async openFilterMenu() {
        const dialog = new FiltersDialog<IAttachmentsFilterItemDictionary>(this.currentFilter, AttachmentFilters.filterMenu);

        const result = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            this.currentFilter = result;
            this.filterAndSort();
        }
    }

    protected clearFilters() {
        this.currentFilter = AttachmentFilters.filterMenu();
        this.filterAndSort();
    }

    protected changeDisplayMode() {
        this.displayMode = this.displayMode === 'grid' ? 'list' : 'grid';
    }
}
