import type { Customer } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import AutomaticPaymentsActions from '../../AutomaticPayments/AutomaticPaymentsActions';
import { MandateHistoryDialog } from '../../Dialogs/AutomaticPayments/MandateHistoryDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import type { AutomaticPaymentProvider } from '../AutomaticPaymentsService';
import AutomaticPaymentsService from '../AutomaticPaymentsService';

export class DirectDebitForm {
    @bindable customer: Customer;
    customerChanged() {
        this.load();
    }
    protected method = ApplicationState.localise('payments.direct-debit');
    protected applicationState = ApplicationState;
    protected providers?: Array<AutomaticPaymentProvider>;
    async load() {
        this.providers = await AutomaticPaymentsService.getDirectDebitProviders(this.customer);
    }

    viewHistory() {
        const dialog = new MandateHistoryDialog(this.customer);
        dialog.show(DialogAnimation.SLIDE);
    }

    cancelDirectDebit() {
        AutomaticPaymentsActions.cancelDirectDebit(this.customer._id);
    }

    configure() {
        AutomaticPaymentsActions.configure();
    }

    @computedFrom('customer', 'applicationState')
    get hasProvider() {
        return ApplicationState.hasDirectDebitProvider();
    }
}
