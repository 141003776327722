import type { Customer, ICustomerPaymentProvidersData } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import type { AutomaticPaymentProvider } from '../../AutomaticPayments/AutomaticPaymentsService';
import AutomaticPaymentsService from '../../AutomaticPayments/AutomaticPaymentsService';
import './default-automatic-payment-provider.scss';

export class DefaultAutomaticPaymentProvider {
    @bindable customer?: Customer;
    protected value: '' | keyof ICustomerPaymentProvidersData | string;
    protected providers: undefined | Array<AutomaticPaymentProvider>;

    protected loading: boolean;

    async init() {
        this.providers = await AutomaticPaymentsService.availableProviders(this.customer);
        if (this.customer) {
            this.value = this.customer.automaticPaymentMethod || '';
            return;
        }

        switch (
        ApplicationState.account.defaultAutoPaymentCollection as true | typeof ApplicationState.account.defaultAutoPaymentCollection // Legacy check for old setting.
        ) {
            case true:
            case 'gocardless':
                this.value = 'gocardless';
                break;
            case 'stripe':
                this.value = 'stripe';
                break;
            default:
                if (ApplicationState.account.defaultAutoPaymentCollection) {
                    this.value = ApplicationState.account.defaultAutoPaymentCollection;
                } else {
                    this.value = '';
                }
                break;
        }
    }
    async customerChanged() {
        this.init();
    }

    async attached() {
        this.loading = true;
        await this.init();
        this.loading = false;
    }

    onChange(event: any) {
        const method = event?.target?.value;
        AutomaticPaymentsService.updateDefaultAutomaticPaymentProvider(this.customer, method || 'None');
    }
}
