import type { StoredObject } from '@nexdynamic/squeegee-common';
import { Linker } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { AttachmentService } from '../../Attachments/AttachmentService';
import { AttachmentsDialog } from '../../Attachments/Dialogs/AttachmentsDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { Logger } from '../../Logger';
import type { IStoredObjectModel } from '../../utils/StoredObjectUtils';
import StoredObjectUtils from '../../utils/StoredObjectUtils';

export class AttachmentSummaryListItemCustomElement {
    @bindable parent: StoredObject;
    @bindable showIcon = true;

    private linker?: Linker;
    private model?: IStoredObjectModel;

    private _dataRefreshedSub: Subscription;
    protected attached() {
        if (!this.parent) Logger.warn('No parent found for attachment summary list item');
        else {
            this.linker = AttachmentService.getLinkerForParent(this.parent._id);
            this.model = StoredObjectUtils.getUIModelForStoredObject(this.parent);
        }

        this._dataRefreshedSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.hasAnyType('linkers')) {
                const linker = event.filterByType<Linker>('linkers').find(linker => linker.parentId === this.parent._id);
                if (linker && linker.linkerType === 'attachment') this.linker = Object.assign({}, linker);
            }
        });
    }

    protected detached() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
    }

    @computedFrom('parent', 'linker', 'model')
    get title() {
        if (!this.parent || !this.model) return;
        return ApplicationState.localise('attachments.resource-attachments', { resourceName: this.model?.name || '' });
    }

    @computedFrom('parent', 'linker', 'model')
    get description() {
        if (!this.parent || !this.model) return;
        const amount = Linker.count(this.linker);
        if (amount) {
            return ApplicationState.localise('attachments.resource-number-attached', {
                resourceName: this.model?.name || '',
                amount: amount.toString(),
            });
        }
        return ApplicationState.localise('attachments.resource-no-attachments', { resourceName: this.model?.name || '' });
    }

    @computedFrom('parent', 'linker', 'model')
    get count() {
        if (!this.linker) return;

        return Linker.count(this.linker);
    }

    protected view() {
        const header = this.model?.title
            ? `${this.model.title}${this.model.description ? ', ' : ''}${this.model.description || ''}`
            : ApplicationState.localise('general.attachments');
        new AttachmentsDialog(this.parent, header, this.model?.icon).show();
    }
}
