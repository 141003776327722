import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { bindable, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AccountActions } from '../../Account/AccountActions';
import { ApplicationState } from '../../ApplicationState';
import './delete-account.scss';

@inject(Router)
export class DeleteAccountCustomElement {
    @bindable account?: ICustomerSummary;
    pending = false;
    attached() {
        this.pending = ApplicationState.account.deleteAccountRequestedOn ? true : false;
    }
    deleteAccount() {
        AccountActions.processAccountDelete();
    }
}
