// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-form__card-container {
  border-radius: 6px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.payment-form__update {
  margin-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/payment-form.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAAR;AAEI;EACI,iBAAA;AAAR","sourcesContent":[".payment-form {\n    &__card-container {\n        border-radius: 6px;\n        padding-right: 16px;\n        padding-top: 8px;\n        padding-bottom: 8px;\n        background-color: #fafafa;\n        display: flex;\n        align-items: center;\n        flex-wrap: wrap;\n    }\n    &__update {\n        margin-left: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
