// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-invoices {
  width: 100%;
  padding: 16px;
}
.account-invoices__header {
  padding: 8px 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.account-invoices__header :first-child {
  flex: 1;
}
.account-invoices__none {
  text-align: center;
  padding: 1rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/account-invoices.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;AACJ;AACI;EACI,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AACR;AACQ;EACI,OAAA;AACZ;AAEI;EACI,kBAAA;EACA,aAAA;EACA,gBAAA;AAAR","sourcesContent":[".account-invoices {\n    width: 100%;\n    padding: 16px;\n\n    &__header {\n        padding: 8px 16px;\n        font-size: 18px;\n        font-weight: bold;\n        display: flex;\n        align-items: center;\n\n        :first-child {\n            flex: 1;\n        }\n    }\n    &__none {\n        text-align: center;\n        padding: 1rem;\n        font-weight: 500;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
