// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-balance {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.account-balance__header {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.account-balance__header div:first-child {
  flex: 1;
}
.account-balance__body {
  display: flex;
  flex-direction: row;
}
.account-balance__body__empty {
  padding: 16px;
  font-size: 16px;
}
.account-balance__body__empty a {
  cursor: pointer;
}
.account-balance__body__section-left {
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px;
  min-height: 68px;
}
.account-balance__body__section-left > span {
  float: left;
}
.account-balance__body__section-right {
  padding: 16px;
  flex: 1;
}
.account-balance__footer {
  padding: 16px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/account-balance.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AACJ;AACI;EACI,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AACR;AAAQ;EACI,OAAA;AAEZ;AACI;EACI,aAAA;EACA,mBAAA;AACR;AAAQ;EACI,aAAA;EACA,eAAA;AAEZ;AADY;EACI,eAAA;AAGhB;AAAQ;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;AAEZ;AADY;EACI,WAAA;AAGhB;AAAQ;EACI,aAAA;EACA,OAAA;AAEZ;AAEI;EACI,aAAA;EACA,gBAAA;AAAR","sourcesContent":[".account-balance {\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n\n    &__header {\n        padding: 8px 16px;\n        font-weight: bold;\n        font-size: 18px;\n        display: flex;\n        align-items: center;\n        div:first-child {\n            flex: 1;\n        }\n    }\n    &__body {\n        display: flex;\n        flex-direction: row;\n        &__empty {\n            padding: 16px;\n            font-size: 16px;\n            a {\n                cursor: pointer;\n            }\n        }\n        &__section-left {\n            display: flex;\n            align-items: center;\n            position: relative;\n            padding: 16px;\n            min-height: 68px;\n            & > span {\n                float: left;\n            }\n        }\n        &__section-right {\n            padding: 16px;\n            flex: 1;\n        }\n    }\n\n    &__footer {\n        padding: 16px;\n        font-weight: 500;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
