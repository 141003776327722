import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { Api } from '../../Server/Api';
import './AccountOverview.scss';
export class AccountOverview {
    account?: ICustomerSummary;
    loading = true;
    online: boolean;
    applicationState = ApplicationState;
    modificationAllowed?: boolean;
    async activate() {
        this.loading = true;
        this.account = await AccountBillingService.getAccount();
        const status = this.account?.subscriptions?.[0]?.status;
        this.modificationAllowed = status !== 'unpaid' && status !== 'expired';
        requestAnimationFrame(() => {
            this.loading = false;
        });
        this.online = Api.isConnected;
    }
}
