import type { Attachment, IFabAction } from '@nexdynamic/squeegee-common';
import { BindingEngine, computedFrom, inject, observable } from 'aurelia-framework';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { FabWithActions } from '../FabWithActions';
import type { Filter } from '../Filters/Filter';
import { FiltersDialog } from '../Filters/FiltersDialog';
import { AttachmentActions } from './AttachmentActions';
import * as AttachmentFilters from './AttachmentFilters';
import type { IAttachmentsFilterItemDictionary } from './AttachmentFilters';
import { filterMenu } from './AttachmentFilters';
import './Attachments.scss';
import { AttachmentService } from './AttachmentService';
@inject(BindingEngine)
export class Attachments {
    @observable protected searchText: string;
    searchTextChanged() {
        this.filterAndSort();
    }
    protected filteredAttachments: Array<Attachment> = [];
    private attachments: Array<Attachment> = [];
    protected currentFilter: Filter<IAttachmentsFilterItemDictionary> = filterMenu();
    protected displayMode: 'grid' | 'list' = 'list';
    private _dataRefreshedSub: Subscription;
    protected fabActions: Array<IFabAction>;
    constructor(protected bindingEngine: BindingEngine) {}

    attached() {
        FabWithActions.register(this.getFabActions());
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            (event: DataRefreshedEvent) => event.hasAnyType('linkers', 'attachments') && this.loadData()
        );

        this.loadData();
    }

    detached() {
        this._dataRefreshedSub && this._dataRefreshedSub.dispose();
        FabWithActions.unregister();
    }

    loadData() {
        this.attachments = AttachmentService.getAll();
        this.filterAndSort();
    }

    private getFabActions(): Array<IFabAction> {
        return [
            {
                tooltip: 'general.upload-file',
                actionType: 'action-upload',
                handler: this._delegateAddNewAttachment,
                roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Reporting', 'Worker'],
            },
        ];
    }

    private filterAndSort() {
        AttachmentFilters.filterList(this.attachments, this.filteredAttachments, this.searchText, this.currentFilter);
        this.filteredAttachments = AttachmentFilters.sortList(this.filteredAttachments, this.currentFilter);
    }

    @computedFrom('attachments')
    public get attachmentsCount() {
        return this.attachments.length;
    }

    protected async openFilterMenu() {
        const dialog = new FiltersDialog<IAttachmentsFilterItemDictionary>(this.currentFilter, filterMenu);

        const result = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            this.currentFilter = result;
            this.filterAndSort();
        }
    }

    protected clearFilters() {
        this.currentFilter = filterMenu();
        this.filterAndSort();
    }

    protected changeDisplayMode() {
        this.displayMode = this.displayMode === 'grid' ? 'list' : 'grid';
    }

    protected open(attachment: Attachment) {
        AttachmentActions.viewDetails(attachment);
    }

    private _delegateAddNewAttachment = async () => {
        await AttachmentActions.addNew();
    };

    protected selectedAttachmentAnchor: EventTarget | null;
    protected openAttachmentMenu(event: MouseEvent, attachmentMenu: any) {
        if (attachmentMenu) attachmentMenu.open = !attachmentMenu.open;
        this.selectedAttachmentAnchor = event.currentTarget;
    }
}
