// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 340px) {
  .account-billing-form .p-a-2 {
    padding: 16px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/account-billing-form.scss"],"names":[],"mappings":"AAEA;EAEQ;IACI,wBAAA;EAFV;AACF","sourcesContent":[".account-billing-form {\n}\n@media (max-width: 340px) {\n    .account-billing-form {\n        .p-a-2 {\n            padding: 16px !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
