// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.current-plan {
  width: 100%;
  padding: 16px;
}
.current-plan__header {
  padding: 16px;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}
.current-plan__title {
  flex: 1;
}
.current-plan__plan {
  margin-top: 8px;
  max-width: 280px;
  text-align: center;
  display: block;
  margin-bottom: 8px;
  margin-right: 16px;
}
.current-plan__summary {
  padding: 16px;
}
.current-plan__summary ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.current-plan__summary ul li {
  font-weight: 500;
  padding: 4px 0;
  font-size: 16px;
  display: flex;
}
.current-plan__summary ul li div:first-child {
  flex: 1;
}
.current-plan__total {
  font-weight: 500;
  font-size: 16px;
  display: flex;
}
.current-plan__total div:first-child {
  flex: 1;
}
.current-plan__total-price {
  clear: both;
  font-weight: normal;
}
.current-plan__total-price b {
  margin-left: 8px;
  text-align: right;
  float: right;
}
.current-plan__footer {
  padding: 8px 16px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/current-plan.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;AACJ;AAAI;EACI,aAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;AAER;AAAI;EACI,OAAA;AAER;AAAI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AAER;AAAI;EACI,aAAA;AAER;AADQ;EACI,gBAAA;EACA,SAAA;EACA,UAAA;AAGZ;AAFY;EACI,gBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;AAIhB;AAHgB;EACI,OAAA;AAKpB;AAAI;EACI,gBAAA;EACA,eAAA;EACA,aAAA;AAER;AADQ;EACI,OAAA;AAGZ;AAAI;EACI,WAAA;EAMA,mBAAA;AAHR;AAFQ;EACI,gBAAA;EACA,iBAAA;EACA,YAAA;AAIZ;AAAI;EACI,iBAAA;AAER","sourcesContent":[".current-plan {\n    width: 100%;\n    padding: 16px;\n    &__header {\n        padding: 16px;\n        font-size: 16px;\n        display: flex;\n        flex-wrap: wrap;\n    }\n    &__title {\n        flex: 1;\n    }\n    &__plan {\n        margin-top: 8px;\n        max-width: 280px;\n        text-align: center;\n        display: block;\n        margin-bottom: 8px;\n        margin-right: 16px;\n    }\n    &__summary {\n        padding: 16px;\n        ul {\n            list-style: none;\n            margin: 0;\n            padding: 0;\n            li {\n                font-weight: 500;\n                padding: 4px 0;\n                font-size: 16px;\n                display: flex;\n                div:first-child {\n                    flex: 1;\n                }\n            }\n        }\n    }\n    &__total {\n        font-weight: 500;\n        font-size: 16px;\n        display: flex;\n        div:first-child {\n            flex: 1;\n        }\n    }\n    &__total-price {\n        clear: both;\n        b {\n            margin-left: 8px;\n            text-align: right;\n            float: right;\n        }\n        font-weight: normal;\n    }\n    &__footer {\n        padding: 8px 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
