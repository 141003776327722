import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-binding';
import { inject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { AccountActions } from '../../Account/AccountActions';
import type { PaymentDetails } from '../../Account/AccountBillingService';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { LoaderEvent } from '../../Events/LoaderEvent';
import './account-balance.scss';

@inject(Router)
export class AccountBalanceCustomElement {
    loading = false;
    account?: ICustomerSummary;
    paymentDetails?: PaymentDetails;
    protected balance?: number;
    protected currencySymbol: string | undefined;

    async attached() {
        this.loading = true;
        this.account = await AccountBillingService.getAccount();
        this.paymentDetails = await AccountBillingService.getPaymentDetails();

        requestAnimationFrame(() => {
            this.loading = false;
        });

        const balanceDetails = await ApplicationState.updateAccountBalance();
        this.balance = balanceDetails?.balance;
        this.currencySymbol = balanceDetails?.currencySymbol;
    }
    async payBalance() {
        new LoaderEvent(true, true, ApplicationState.localise('account.clearing-balance'));
        await AccountActions.payBalance();

        const balanceDetails = await ApplicationState.updateAccountBalance();
        this.balance = balanceDetails?.balance;
        this.currencySymbol = balanceDetails?.currencySymbol;

        new LoaderEvent(false);
    }

    @computedFrom('paymentDetails.card', 'paymentDetails.card.exp_year')
    get card() {
        return this.paymentDetails?.card;
    }

    @computedFrom('balance')
    get balanceLabel() {
        return !this.balance ? '' : this.balance > 0 ? 'financials.owed-label' : 'financials.credit-label';
    }
}
