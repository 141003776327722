// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-menu .mdc-menu {
  left: initial !important;
  right: 0;
}
.attachment-menu .mdc-list-item__primary-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.attachment-menu .attachment-menu-text {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Attachments/Components/attachment-menu.scss"],"names":[],"mappings":"AAEI;EACI,wBAAA;EACA,QAAA;AADR;AAII;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,qBAAA;AAFR;AAKI;EACI,iBAAA;AAHR","sourcesContent":[".attachment-menu {\n    //Hack can't seem to make menu respect main layout size so on desktop menu gets hidden under a dialog\n    .mdc-menu {\n        left: initial !important;\n        right: 0;\n    }\n\n    .mdc-list-item__primary-text {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n        align-content: center;\n    }\n\n    .attachment-menu-text {\n        margin-left: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
