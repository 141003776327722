// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-thumbnail__img {
  height: 42px;
  width: 42px;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/Attachments/Components/attachment-thumbnail.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,WAAA;EACA,sBAAA;AAAR","sourcesContent":[".attachment-thumbnail {\n    &__img {\n        height: 42px;\n        width: 42px;\n        background-size: cover;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
