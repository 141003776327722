import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { bindable, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ApplicationState } from '../../ApplicationState';
import './users-summary.scss';

@inject(Router)
export class UsersSummaryCustomElement {
    @bindable account?: ICustomerSummary;

    constructor(private router: Router) { }

    addUsers() {
        this.router.navigate('/account/subscription');
    }

    get unpaid() {
        return ApplicationState.subscription?.status === 'unpaid';
    }
}
