import type { StoredObjectResourceTypes } from '@nexdynamic/squeegee-common';

export class Cache {
    private static _cache: { [key: string]: { dependencies: Readonly<Array<StoredObjectResourceTypes>>; data: any } } = {};
    private static _cacheTimers: { [key: string]: any } = {};

    public static get<TObjectType>(key: string): TObjectType {
        return Cache._cache[key] && Cache._cache[key].data;
    }

    public static set<TObjectType>(key: string, data: TObjectType, dependencies: Array<StoredObjectResourceTypes>, dontExpire = false) {
        Cache._cache[key] = { dependencies, data };
        clearTimeout(Cache._cacheTimers[key]);
        if (dontExpire) return;
        Cache._cacheTimers[key] = setTimeout(() => {
            delete Cache._cache[key];
            delete Cache._cacheTimers[key];
        }, 10000);
    }

    public static remove(key: string) {
        clearTimeout(Cache._cacheTimers[key]);
        delete Cache._cache[key];
        delete Cache._cacheTimers[key];
    }

    public static removeDependentCache(dependencies: Array<StoredObjectResourceTypes>) {
        for (const dependency of dependencies) {
            for (const key in Cache._cache) {
                const cache = Cache._cache[key];
                if (cache.dependencies.some(x => x === dependency)) {
                    this.remove(key);
                }
            }
        }
    }

    public static flush() {
        Cache._cache = {};

        for (const key in Cache._cacheTimers) {
            clearTimeout(Cache._cacheTimers[key]);
            delete Cache._cacheTimers[key];
        }
    }
}

(window as any).Cache = Cache;
