import type { PaymentMethod } from '@stripe/stripe-js';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import './card-on-file.scss';
export class CardOnFileCustomElement {
    @bindable card?: PaymentMethod.Card;

    @computedFrom('card.exp_month', 'card.exp_year')
    get cardInfo() {
        if (!this.card) return;
        return `${this.card.brand} - ${ApplicationState.localise('general.expires')} ${this.card.exp_month}/${this.card.exp_year}`;
    }
    @computedFrom('card', 'card.brand')
    get cardImg() {
        if (!this.card) return '';
        return `/images/card-brands/${this.card.brand}.svg`;
    }
}
