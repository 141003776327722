import type { I18N } from 'aurelia-i18n';
import type { Router } from 'aurelia-router';
import moment from 'moment';
import { SqueegeeLocalStorage } from './Data/SqueegeeLocalStorage';

export class ApplicationEnvironment {
    private static _today: string;
    static aurelia: any;
    public static get today() {
        if (ApplicationEnvironment._today === undefined) {
            setInterval(() => ApplicationEnvironment.setToday(), 3600000);
            ApplicationEnvironment.setToday();
        }
        return ApplicationEnvironment._today;
    }
    private static setToday() {
        this._today = moment().format('YYYY-MM-DD');
    }
    public static router: Router;
    public static i18nInstance: I18N;

    private static _dataBatchSize: number | undefined;
    public static get dataBatchSize() {
        if (ApplicationEnvironment._dataBatchSize === undefined) {
            ApplicationEnvironment._dataBatchSize = Number(SqueegeeLocalStorage.getItem('dataBatchSize')) || 500;
        }
        return ApplicationEnvironment._dataBatchSize;
    }

    public static set dataBatchSize(value: number) {
        SqueegeeLocalStorage.setItem('dataBatchSize', value.toString());
        ApplicationEnvironment._dataBatchSize = value;
    }

    private static _i18nDebugEnabled: boolean | undefined;
    public static get i18nDebugEnabled() {
        if (ApplicationEnvironment._i18nDebugEnabled === undefined) {
            ApplicationEnvironment._i18nDebugEnabled = SqueegeeLocalStorage.getItem('localisationDebugging') === 'true';
        }
        return ApplicationEnvironment._i18nDebugEnabled;
    }

    public static set i18nDebugEnabled(value: boolean) {
        ApplicationEnvironment._i18nDebugEnabled = value;
        value ? SqueegeeLocalStorage.setItem('localisationDebugging', 'true') : SqueegeeLocalStorage.removeItem('localisationDebugging');
    }
}
