// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-img-container {
  background-color: #d2d2d2;
  padding: 32px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.attachment-img-container__img {
  cursor: pointer;
  height: 100%;
  max-height: 50vh;
  background-repeat: no-repeat;
  border-radius: 6px;
  filter: drop-shadow(0 0 10px #777);
  display: block;
  width: 100%;
  object-fit: scale-down;
}
.attachment-img-container__loader {
  max-height: 50vh;
  width: 100%;
  border-radius: 6px;
  background-color: #acacac;
  filter: drop-shadow(0 0 10px #c5c5c5);
  overflow: hidden;
  object-fit: scale-down;
}`, "",{"version":3,"sources":["webpack://./src/Attachments/Components/attachment-img-container.scss"],"names":[],"mappings":"AAGA;EACI,yBAJ0B;EAK1B,aAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAGI;EACI,eAAA;EACA,YAAA;EACA,gBAAA;EACA,4BAAA;EACA,kBAAA;EACA,kCAdgB;EAehB,cAAA;EACA,WAAA;EACA,sBAAA;AADR;AAII;EACI,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,qCAAA;EACA,gBAAA;EACA,sBAAA;AAFR","sourcesContent":["$attachment-img-container-bg: #d2d2d2;\n$attachment-img-filter: drop-shadow(0 0 10px #777);\n\n.attachment-img-container {\n    background-color: $attachment-img-container-bg;\n    padding: 32px;\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    &__img {\n        cursor: pointer;\n        height: 100%;\n        max-height: 50vh;\n        background-repeat: no-repeat;\n        border-radius: 6px;\n        filter: $attachment-img-filter;\n        display: block;\n        width: 100%;\n        object-fit: scale-down;\n    }\n\n    &__loader {\n        max-height: 50vh;\n        width: 100%;\n        border-radius: 6px;\n        background-color: darken($attachment-img-container-bg, 15%);\n        filter: drop-shadow(0 0 10px darken($attachment-img-container-bg, 5%));\n        overflow: hidden;\n        object-fit: scale-down;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
