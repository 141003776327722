import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { stripHtml } from '../Utilities';
export class ReleaseNotesDialog extends CustomDialog<void> {
    protected needsTranslating = !ApplicationState.account.language.startsWith('en');
    protected releaseNotesHtml: string = require('./ReleaseNotes.md').default;
    protected raw = encodeURIComponent(stripHtml(this.releaseNotesHtml));
    protected releaseNotesTitle = `Release Notes ${ApplicationState.version}`;
    protected languageCode = ApplicationState.account.language.slice(0, 2);

    constructor() {
        super('releaseNotesDialog', '../About/ReleaseNotes.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'details-dialog',
            isSecondaryView: true,
        });
        this.releaseNotesHtml +=
            '<br /><hr><center><a target="system" style="text-decoration: none; color: #0288d1" href="https://squeeg.ee/blog">View Previous Release Notes</a></center><hr>';
    }
}
