import type { AlertType, TranslationKey } from '@nexdynamic/squeegee-common';
import { Currency, Localisation } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { SelectGridDialog, SelectGridDialogType } from '../Dialogs/SelectGrid/SelectGridDialog';
import { SignatureDialog } from '../Dialogs/Signatures/SignatureDialog';
import { TextDialog } from '../Dialogs/TextDialog';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { LanguagesAndCountries } from '../LanguagesAndCountries';
import { Logger } from '../Logger';
import { RouteUtil } from '../Routes';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { UserAuthorisationService } from '../Users/UserAuthorisationService';
import { UserService } from '../Users/UserService';
import { t } from "../t";
import { configureTwoFactorAuth } from './TwoFactorAuth/configureTwoFactorAuth';
import { getCurrentTwoFactor } from './TwoFactorAuth/getCurrentTwoFactor';

export class EditProfileDialog extends CustomDialog<void> {
    protected account = ApplicationState.account;
    protected stateFlags = ApplicationState.stateFlags;
    private _applicationStateUpdatedSub: Subscription;
    protected isAppleMobileApp = !ApplicationState.canShowAccountManagement;
    protected userEmail = RethinkDbAuthClient?.session?.email as string;
    protected dataEmail = ApplicationState.dataEmail;
    protected isOwnerOrAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected currentLandingPage = ApplicationState.landingPageRoute?.title || 'landing.page-last-location';

    constructor(protected firstTime = false) {
        super(
            'editProfile',
            '../Account/EditProfileDialog.html',
            undefined,
            firstTime
                ? {
                    okLabel: 'get.started',
                    coverViewport: true,
                    cssClass: 'first-time-profile',
                    cancelLabel: '',
                    allowCancel: false,
                    showCloseX: false,
                }
                : {
                    cancelLabel: '',
                    okLabel: '',
                    isSecondaryView: true,
                }
        );

        this._applicationStateUpdatedSub = ApplicationStateUpdatedEvent.subscribe(() => {
            this.account = ApplicationState.account;
        });

        firstTime && (ApplicationState.instance.account.currency = ApplicationState.countryConfig('currencyCode') as keyof Currency);

        this.pushAlerts = ApplicationState.getPushAlertsForUser(this.userEmail);
        this._init();
    }

    protected pushAlerts: Array<AlertType>;
    protected localise = ApplicationState.localise;
    protected async setAlertTypes() {
        const pushAlerts = await ApplicationState.selectPushAlerts(this.pushAlerts);
        if (!pushAlerts) return;

        this.pushAlerts = pushAlerts;
        ApplicationState.updatePushAlerts(this.userEmail, pushAlerts);
    }

    protected get marketingEnabled() {
        return ApplicationState.account.isOptedInToMarketing;
    }

    protected set marketingEnabled(value: boolean) {
        ApplicationState.account.isOptedInToMarketing = value;
        ApplicationState.save();
    }

    protected signature = ApplicationState.mySignature;

    public async uploadSignature() {
        const email = RethinkDbAuthClient.session?.email;
        if (!email) return;

        const user = UserService.getUser(email);
        if (!user) return;

        const signatureDialog = new SignatureDialog(
            'signature.create-settings-dialog',
            'general.save',
            true,
            this.signature?.signature,
            true,
            this.signature?.fullname || user.name,
            t('signature.create-profile-signature'));

        const signingResult = await signatureDialog.show();
        if (!signatureDialog.cancelled) {
            ApplicationState.setSetting('global.staff-signature', signingResult, email);
            this.signature = signingResult;
        }
    }

    public dispose() {
        this._applicationStateUpdatedSub && this._applicationStateUpdatedSub.dispose();
        super.dispose();
    }

    protected async changePassword() {
        let currentPassword = '';
        if (!RethinkDbAuthClient.session?.freeDevice) {
            const currentPasswordDialog = new TextDialog(
                'dialogs.profile-change-password-current-password-title',
                'dialogs.profile-change-password-current-password-description',
                '',
                'dialogs.profile-change-password-current-password-title',
                v => (v.length > 0 ? true : 'general.password-required'),
                true
            );
            currentPassword = await currentPasswordDialog.show();
            if (currentPasswordDialog.cancelled) return;
        }

        const newPasswordDialog = new TextDialog(
            'dialogs.profile-change-password-new-password-title',
            'dialogs.profile-change-password-new-password-description',
            '',
            'dialogs.profile-change-password-new-password-title',
            v =>
                v.length < 8
                    ? ApplicationState.localise('dialogs.profile-change-password-new-password-length-error', { length: '8' })
                    : v === currentPassword
                        ? 'dialogs.profile-change-password-new-password-same-error'
                        : true,
            true
        );
        const newPassword = await newPasswordDialog.show();
        if (newPasswordDialog.cancelled) return;

        const repeatNewPasswordDialog = new TextDialog(
            'dialogs.profile-change-password-repeat-new-password-title',
            'dialogs.profile-change-password-repeat-new-password-description',
            '',
            'dialogs.profile-change-password-repeat-new-password-title',
            v => (v === newPassword ? true : 'dialogs.profile-change-password-repeat-new-password-not-matching'),
            true
        );
        await repeatNewPasswordDialog.show();
        if (repeatNewPasswordDialog.cancelled) return;

        if (
            !(await new Prompt('dialogs.profile-change-password-confirm-ok-button', 'dialogs.profile-change-password-confirm-description', {
                okLabel: 'dialogs.profile-change-password-confirm-ok-button',
            }).show())
        )
            return;



        const result = await Api.post<{ success: boolean; error: string }>(null, '/api/authentication/change-password', {
            currentPassword,
            newPassword,
        });
        if (!result || !result.data)
            return new Prompt('prompts.error-title', 'dialogs.profile-change-password-unknown-error', { cancelLabel: '' }).show();


        if (!result || !result.data)
            return new Prompt('prompts.error-title', 'dialogs.profile-change-password-unknown-error', { cancelLabel: '' }).show();

        if (!result.data.success) {
            return new Prompt('prompts.error-title', ApplicationState.localise('result-data.error', { result: result.data.error }), { cancelLabel: '' }).show();
        }

        const signOutOthers = await new Prompt(
            'dialogs.profile-change-password-new-password-set',
            'dialogs.profile-change-password-new-password-set-force-sign-out',
            { okLabel: 'dialogs.profile-change-password-new-password-set-force-sign-out-button', cancelLabel: 'general.finished' }
        ).show();

        if (signOutOthers) {
            await Api.signoutOtherDevices();
            await new Prompt('general.complete', 'dialogs.profile-change-password-signout-complete', { cancelLabel: '' }).show();
        }
    }

    protected async configureTwoFactorAuth() {
        configureTwoFactorAuth();
    }

    private _twoFactorAuthDescription: TranslationKey;
    protected get twoFactorAuthDescription() {
        if (this._twoFactorAuthDescription === undefined) {
            this._twoFactorAuthDescription = t('empty.string');
            getCurrentTwoFactor().then(method => {
                if (method.type !== 'none') {
                    const type = method.type === 'totp' ? 'authenticator app' : method.type;
                    this._twoFactorAuthDescription = ApplicationState.localise('two-factor.setting-description-enabled', { type });
                } else {
                    this._twoFactorAuthDescription = ApplicationState.localise('two-factor.setting-description-disabled');
                }
            });
        }
        return this._twoFactorAuthDescription;
    }

    private _currentLanguage: { value: string; text: TranslationKey } | undefined;
    protected get currentLanguage() {
        if (this._currentLanguage === undefined) {
            const languageCode = ((ApplicationState.account.language || 'en-GB').match(/^(..)-..$/) || ['', 'en'])[1];
            const languageCodeKey = `languages.${languageCode}` as TranslationKey;
            if (Localisation.isLocalised(languageCodeKey)) {
                this._currentLanguage = { value: languageCode, text: languageCodeKey };
            }
        }
        return this._currentLanguage || { value: 'en', text: 'languages.en' };
    }

    private _currentCountry: { value: string; text: TranslationKey } | undefined;
    protected get currentCountry() {
        if (this._currentCountry === undefined) {
            const countryCode = ((ApplicationState.account.language || 'en-GB').match(/^..-(..)$/) || ['', 'GB'])[1];
            const countryCodeKey = <TranslationKey>`countries.${countryCode}`;
            if (Localisation.isLocalised(countryCodeKey)) return { value: countryCode, text: countryCodeKey };
        }
        return this._currentCountry || { value: 'GB', text: 'countries.GB' };
    }

    private _currentCurrency: { value: string; text: TranslationKey } | undefined;
    protected get currentCurrency() {
        if (this._currentCurrency === undefined) {
            let currency = Currency.get(ApplicationState.account.currency as keyof Currency);
            this._currentCurrency = currency && {
                value: ApplicationState.account.currency,
                text: <TranslationKey>`${currency.name} (${currency.symbol})`,
            };
            if (this._currentCurrency === undefined) {
                const currencyCode = ApplicationState.countryConfig('currencyCode', this.currentCountry.value) as keyof Currency;
                currency = Currency.get(currencyCode);
                this._currentCurrency =
                    (currency && { value: currencyCode, text: <TranslationKey>`${currency.name} (${currency.symbol})` }) || undefined;
            }
        }
        return this._currentCurrency || { value: null, text: '??' };
    }

    public async changeCurrency() {
        try {
            const currencies = Currency.all
                .map(curKey => {
                    const currency = Currency.get(curKey);
                    return ((currency && { value: currency.code, text: <TranslationKey>`${currency.name} (${currency.symbol})` }) ||
                        undefined) as {
                            value: string;
                            text: TranslationKey;
                        };
                })
                .filter(c => !!c)
                .sort((a, b) => (a.text > b.text ? 1 : -1));
            const currencyDialog = new Select('general.currency', currencies, 'text', 'value', this.currentCurrency.value);
            currencyDialog.disableLocalisation = true;
            const selectedCurrency = await currencyDialog.show(DialogAnimation.SLIDE);
            if (!currencyDialog.cancelled) {
                this._currentCurrency = selectedCurrency;
                ApplicationState.account.currency = (this._currentCurrency && (this._currentCurrency.value as keyof Currency)) || 'GBP';
                ApplicationState.save();
            }
        } catch (error) {
            Logger.error('Error during change currency', error);
        }
    }

    public async changeLanguage() {
        try {
            const beta = ApplicationState.localise('general.beta');
            const languageDialog = new Select(
                'general.language',
                LanguagesAndCountries.languagesArray.map(l => {
                    const text = ApplicationState.localise(l.text);
                    const betaFlag = l.value !== 'en' ? ' ' + beta : '';
                    const key = text + betaFlag;
                    return {
                        text: key as TranslationKey,
                        value: l.value,
                    };
                }),
                'text',
                'value',
                this.currentLanguage.value
            );
            const selectedLanguage = await languageDialog.show(DialogAnimation.SLIDE);
            if (!languageDialog.cancelled) {
                ApplicationState.setLanguage(selectedLanguage.value);
                selectedLanguage.text = ApplicationState.localise(`languages.${selectedLanguage.value}` as TranslationKey);
                this._currentLanguage = selectedLanguage;
            }
        } catch (error) {
            Logger.error('Error during change the language.', error);
        }
    }

    private async showCountrySelectDialog() {
        try {
            const countryDialog = new Select(
                'general.country',
                LanguagesAndCountries.countriesArray,
                'text',
                'value',
                this.currentCountry.value
            );
            const selectedCountry = await countryDialog.show(DialogAnimation.SLIDE);
            if (!countryDialog.cancelled) {
                this._currentCountry = selectedCountry;
                await ApplicationState.setCountry(this.currentCountry.value);
                const currencyCode = ApplicationState.countryConfig('currencyCode') as keyof Currency;
                const currency = Currency.get(currencyCode);
                this._currentCurrency =
                    (currency && { value: currencyCode, text: <TranslationKey>`${currency.name} (${currency.symbol})` }) || undefined;
            }
        } catch (error) {
            Logger.error('Error during update the country', error);
        }
    }

    protected async changeCountry() {
        await this.showCountrySelectDialog();
    }

    public async selectProfileImage() {
        const images: Array<{ id: number; path: string }> = [];

        for (let id = 1; id <= ApplicationState.totalProfileImages; id++)
            images.push({ id, path: ApplicationState.generateProfileImagePath(id) });

        const dialog = new SelectGridDialog<{ id: number; path: string }>(
            'Profile Background',
            images,
            SelectGridDialogType.IMAGE,
            images.find(img => img.path === ApplicationState.profileImage)
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.setProfileImage(result.id);
        }
    }

    public setAddress() {
        ApplicationState.setAddress();
    }

    public setBusinessAddress() {
        ApplicationState.setBusinessAddress();
    }

    public customProfileImage = ApplicationState.getSetting('global.profile-custom-image-url', '');

    public async updateBusinessName() {
        const dialog = new TextDialog(
            'dialogs.profile-business-name-title',
            'dialogs.profile-business-name-label',
            ApplicationState.account.businessName || '',
            'dialogs.profile-business-name-title',
            (value: string) => (value && value.length > 50 ? 'validation.business-name-length' : true)
        );
        const result = await dialog.show();
        if (!dialog.cancelled) {
            ApplicationState.account.businessName = result;
            for (const user of Object.values(UserAuthorisationService.userAuths)) {
                if (!user) continue;
                user.dataOwnerName = result;
            }
            ApplicationState.save();
        }
    }

    public async updateName() {
        const dialog = new TextDialog(
            'dialogs.profile-your-name-title',
            'dialogs.profile-your-name-label',
            ApplicationState.account.name,
            'dialogs.profile-your-name-title',
            (value: string) =>
                !value || value.length === 0 ? 'validation.name-required' : value.length > 50 ? 'validation.name-max-length' : true
        );
        const result = await dialog.show();
        if (dialog.cancelled) return;

        ApplicationState.account.name = result;
        ApplicationState.save();

        const owner = UserService.getUser(ApplicationState.dataEmail);
        if (!owner) return;

        owner.name = result;

        Data.put(owner);

    }

    public async updateContactNumber() {
        const dialog = new TextDialog(
            'dialogs.profile-your-contact-number-title',
            'dialogs.profile-your-contact-number-label',
            ApplicationState.account.contactNumber || '',
            'dialogs.profile-your-contact-number-title'
        );

        const result = await dialog.show();
        if (!dialog.cancelled) {
            ApplicationState.account.contactNumber = result;
            ApplicationState.save();
        }
    }

    protected hasLaunchNavigator = !!(window as any).launchnavigator && !!(window as any).launchnavigator;
    protected defaultNavigationApp: string;

    protected async clearDefaultNavigator() {
        if (!this.hasLaunchNavigator) return;
        const title = 'clear.default-navigation-app';
        const app = this.defaultNavigationApp ? ' (' + this.defaultNavigationApp + ')' : '';
        const description = ApplicationState.localise('clear.default-navigation-app-description', { app });
        if (
            await new Prompt(title, description, {
                okLabel: 'clear.navigation-app',
                cancelLabel: 'general.cancel',
            }).show()
        ) {
            (window as any).launchnavigator.appSelection.userChoice.clear();
            (window as any).launchnavigator.appSelection.userPrompted.clear();
        }
    }



    protected async chooseLandingPage() {

        const userRole = ApplicationState.userAuthorisation.roles;

        const landingPages = RouteUtil.getRoutes().filter(route =>
            userRole?.some(role => route.settings.roles?.includes(role)) &&
            route.settings.canBeLandingPage &&
            typeof route.route === 'string' &&
            ApplicationState.canNavigateTo(route))
            .map(route => ({
                text: ApplicationState.localise(route.title as TranslationKey),
                value: route.route as string,

            }))
        landingPages.push({
            text: ApplicationState.localise('landing.page-last-location'),
            value: "last-location",
        })
        const dialog = new Select('general.landing-page', landingPages, 'text', 'value', ApplicationState.landingPage);
        const selectedLandingPage = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.landingPage = selectedLandingPage.value === "last-location" ? null : selectedLandingPage.value;
        }
        this.currentLandingPage = ApplicationState.landingPageRoute?.title || 'landing.page-last-location';
        return selectedLandingPage.value;
    }

    protected async signoutOtherDevices() {
        await Api.requestSignoutOtherDevices();
    }


    private _init() {
        if (this.hasLaunchNavigator) {
            try {
                (window as any).launchnavigator.appSelection.userChoice.get(
                    (app: string) => (this.defaultNavigationApp = (window as any).launchnavigator.getAppDisplayName(app))
                );
            } catch (error) {
                Logger.info('Unable to get default navigation app.');
            }
        }
    }
}
