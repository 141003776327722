import type { Customer, IPaymentProviderMetadata, Notification } from '@nexdynamic/squeegee-common';
import { replaceMessageTokensWithModelValues } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { ConnectedServicesDialog } from '../ConnectedServices/ConnectedServicesDialog';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { SendMessageToCustomer } from '../Dialogs/SendMessageToCustomer';
import { EmailTemplateService } from '../EmailTemplates/EmailTemplateService';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Utilities } from '../Utilities';
import { t } from "../t";
import AutomaticPaymentsService from './AutomaticPaymentsService';

export class AutomaticPaymentsActions {
    static async cancelDirectDebit(customerId: string) {
        try {
            if (
                !(await ApplicationState.onlineRequiredCheck(
                    ApplicationState.localise('notifications.automated-payments-connection-required-to-cancel')
                ))
            ) {
                return;
            }

            const customer = CustomerService.getCustomer(customerId);
            if (!customer) throw new Error('Customer not found');

            const dd = ApplicationState.dd;

            const prompt = new Prompt('prompts.confirm-title', 'prompts.gocardless-confirm-cancel-text', {
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
                localisationParams: { dd },
            });

            await prompt.show();
            if (!prompt.cancelled) {
                await CustomerService.cancelCustomerMandate(customer).catch(error => {
                    new NotifyUserMessage('notifications.automated-payments-cancellation-for-customer-error', {
                        dd,
                        name: customer.name,
                    });
                    throw error;
                });
                new NotifyUserMessage('notifications.automated-payments-cancellation-for-customer-success', {
                    dd,
                    name: customer.name,
                });
            }
        } catch (error) {
            Logger.error(`Error during cancel automatic payments for customer`, { customer: customerId, error });
        }
    }

    static configure() {
        new ConnectedServicesDialog().show();
    }

    static async sendInviteMsg(customerId: string, _link?: string): Promise<void> {
        try {
            const link = _link ? _link : await AutomaticPaymentsService.getInviteLink(customerId);
            if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');
            const customer = CustomerService.getCustomer(customerId);
            if (!customer) throw new Error('Customer not found');

            const templateEmail =
                (ApplicationState.messagingTemplates &&
                    ApplicationState.messagingTemplates.emailAutomaticPaymentInvite &&
                    ApplicationState.messagingTemplates.emailAutomaticPaymentInvite.trim()) ||
                '';

            const templateSms =
                (ApplicationState.messagingTemplates &&
                    ApplicationState.messagingTemplates.smsAutomaticPaymentInvite &&
                    ApplicationState.messagingTemplates.smsAutomaticPaymentInvite.trim()) ||
                '';

            const smsMessageModel = await Utilities.getStandardMessageModel({
                customer,
                isHtml: false,
                additionalModelProperties: { ['link']: link } as any,
                templateText: templateSms,
            });
            const emailMessageModel = await Utilities.getStandardMessageModel({
                customer,
                isHtml: true,
                additionalModelProperties: { ['link']: link } as any,
                templateText: templateEmail,
            });

            const email = EmailTemplateService.getSimpleHtml(replaceMessageTokensWithModelValues({
                model: emailMessageModel,
                message: templateEmail,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') }
            }));

            const sms = replaceMessageTokensWithModelValues({
                model: smsMessageModel,
                message: templateSms,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') }
            })


            const newMessage = new SendMessageToCustomer(customer, { sms, email, emailIsHtml: true });
            await newMessage.show();

            if (!newMessage.cancelled) {
                new NotifyUserMessage(
                    newMessage.notificationMethod?.type === 'Email'
                        ? 'notifications.automated-payments-send-invite-email-sent'
                        : 'notifications.automated-payments-send-invite-sms-sent'
                );
                const notification = newMessage.notifications[customerId];
                if (notification) AutomaticPaymentsActions.updateCustomerInviteStatus(notification, customer);
            }
        } catch (error) {
            new NotifyUserMessage('notifications.automated-payments-send-invite-error');
        }
    }

    private static updateCustomerInviteStatus(inviteNotification: Notification, customer: Customer) {
        const goCardlessInvited =
            customer.hideGoCardlessSignupFromInvoice === false ||
            (customer.hideGoCardlessSignupFromInvoice === undefined &&
                !ApplicationState.account.invoiceSettings?.hideGoCardlessSignupFromInvoice);
        const stripeInvited =
            customer.hideStripePayButtonFromInvoice === false ||
            (customer.hideStripePayButtonFromInvoice === undefined &&
                !ApplicationState.account.invoiceSettings?.hideStripePayButtonFromInvoice);
        if (!goCardlessInvited && !stripeInvited) return;

        if (!customer.paymentProviderMetaData) customer.paymentProviderMetaData = {};
        if (goCardlessInvited) {
            if (!customer.paymentProviderMetaData.gocardless)
                customer.paymentProviderMetaData.gocardless = {
                    inviteNotificationId: inviteNotification._id,
                } as IPaymentProviderMetadata;
            else customer.paymentProviderMetaData.gocardless.inviteNotificationId = inviteNotification._id;
        }
        if (stripeInvited) {
            if (!customer.paymentProviderMetaData.stripe)
                customer.paymentProviderMetaData.stripe = {
                    inviteNotificationId: inviteNotification._id,
                } as IPaymentProviderMetadata;
            else customer.paymentProviderMetaData.stripe.inviteNotificationId = inviteNotification._id;
        }
        Data.put(customer);
    }
}

export default AutomaticPaymentsActions;
