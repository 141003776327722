import type { ISession, StandardApiResponse, StandardApiResponseWithData, TranslationKey } from '@nexdynamic/squeegee-common';
import { TextDialog } from '../../Dialogs/TextDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { t } from '../../t';
import { getSecondFactorClient } from './getSecondFactorClient';


export const validateEmailSecondFactorAuth = async (session: ISession): Promise<ISession | undefined> => {
    let attempts = 3;
    const codeTitle = t('two-factor.dialog-email-code-validate');
    const codeDescription = t('two-factor.dialog-email-code-validate-description');

    const client = getSecondFactorClient(session);

    const initUrl = `${Api.apiEndpoint}/api/authentication/multi-factor`;
    const initResult = await client.post<StandardApiResponse>(initUrl, { type: 'email' });
    if (!initResult?.data?.success) return;

    const url = `${Api.apiEndpoint}/api/authentication/multi-factor/email/validate`;
    while (attempts--) {
        new LoaderEvent(false);
        const codeDialog = new TextDialog(
            codeTitle,
            codeDescription,
            '',
            '123456' as TranslationKey,
            v => (v?.length !== 6 ? 'two-factor.dialog-code-requirements' : true),
            undefined,
            undefined,
            'two-factor.dialog-check-code',
            undefined,
            undefined,
            6
        );

        const code = await codeDialog.show();
        if (codeDialog.cancelled)
            return;

        new LoaderEvent(true, true);
        const result = await client.post<StandardApiResponseWithData<ISession>>(url, { code });
        if (!result?.data?.success)
            continue;

        await RethinkDbAuthClient.setSession(result.data.data);
        return result.data.data;
    }
};
