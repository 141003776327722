import type { StandardApiResponse, TranslationKey } from '@nexdynamic/squeegee-common';
import { Prompt } from '../../Dialogs/Prompt';
import { TextDialog } from '../../Dialogs/TextDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Api } from '../../Server/Api';
import { t } from '../../t';

export const enableEmailTwoFactor = async () => {
    try {
        const label = 'email';

        const emailDialog = new TextDialog(
            'general.email',
            'two-factor.dialog-email-address',
            '',
            'two-factor.dialog-email-address-placeholder',
            v => (!v || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ? 'validation.valid-email-required' : true),
            undefined,
            undefined,
            'general.continue'
        );

        const email = await emailDialog.show();
        if (emailDialog.cancelled) return;

        new LoaderEvent(true, true);
        const start = await Api.post<StandardApiResponse>(null, '/api/authentication/multi-factor/email/register-start', { label, email });

        new LoaderEvent(false);
        if (!start?.data?.success) {
            const error = start?.data?.success === false ? start?.data?.error : 'general.unknown-error-occurred';
            await new Prompt('general.error', error as TranslationKey, { cancelLabel: '' }).show();
            return;
        } else {
            const codeTitle = t('two-factor.dialog-email-code', { email });
            const codeDescription = t('two-factor.dialog-email-code-validate-description');
            let attempts = 3;
            while (attempts--) {
                const codeDialog = new TextDialog(
                    codeTitle,
                    codeDescription,
                    '',
                    '123456' as TranslationKey,
                    v => (v?.length !== 6 ? 'two-factor.dialog-code-requirements' : true),
                    undefined,
                    undefined,
                    'two-factor.dialog-check-code',
                    undefined,
                    undefined,
                    6
                );
                const code = await codeDialog.show();
                if (codeDialog.cancelled) return;

                new LoaderEvent(true, true);
                const end = await Api.post<StandardApiResponse>(null, '/api/authentication/multi-factor/email/register-complete', { code });

                new LoaderEvent(false);
                if (!end?.data?.success) {
                    const error = end?.data?.success === false ? end?.data?.error : 'general.unknown-error-occurred';
                    await new Prompt('general.error', error as TranslationKey, { cancelLabel: '' }).show();
                } else {
                    new Prompt('two-factor.dialog-email-success-title', 'two-factor.dialog-email-success-description', {
                        cancelLabel: '',
                    }).show();
                    return;
                }
            }
        }
    } catch (error) {
        new LoaderEvent(false);
        await new Prompt('general.error', 'general.unknown-error-occurred', { cancelLabel: '' }).show();
        return;
    }
};
