import type { Attachment, StoredObjectResourceTypes } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import type { FilterItemDictionary, IFilterItem, IOption } from '../Filters/Filter';
import { Filter } from '../Filters/Filter';
import { Logger } from '../Logger';
import { AttachmentService } from './AttachmentService';

/**
 * Determines wether or not the passed attachment name contains the passed text
 * @param {Attachment} attachment
 * @param {string} text
 * @param {boolean} [exactMatch=false] Require the match to be exact and not a partial match
 * @return {*}  {boolean}
 */
export const nameMatch = (attachment: Attachment, text: string, exactMatch = false): boolean => {
    if (typeof text !== 'string') return false;
    const searchText = text.trim().toLowerCase();
    const attachmentName = attachment.name.toLowerCase();
    if (searchText && exactMatch) return attachmentName === searchText;
    if (searchText && attachmentName.search(searchText) > -1) return true;
    return false;
};
/**
 * Determines wether or not the passed attachment mimeType matches
 * @param {Attachment} attachment
 * @param {string} mimeType
 * @param {boolean} [exactMatch=true] Require the match to be exact and not a partial match
 * @return {*}
 */
export const mimeTypeMatch = (attachment: Attachment, mimeType: string, exactMatch = true) => {
    // Check that the passed mimeType is of type string
    if (typeof mimeType === 'string') {
        // if exactMatch is passed then do a exact compare
        if (exactMatch) return attachment.mimeType === mimeType.trim();
        // if exactMatch is false search the attachment mimeType string for the passed mimeType
        if (mimeType.search(attachment.mimeType) > -1) return true;
    }
    return false;
};

/**
 * Determines wether or not the passed attachment should be included given the attached to filter
 * @param {Attachment} attachment
 * @param {Filter<IAttachmentsFilterItemDictionary>} filter
 * @return {*}  {boolean}
 */
const attachedToFilter = (attachment: Attachment, filter: Filter<IAttachmentsFilterItemDictionary>): boolean => {
    const filterOptions = filter.filterOptions.attachedTo.selectedOptions || [];
    let include = false;
    /**
     * TODO while we are storing data in memory this get is fast but, we will need change it if we get data from a db.
     * Get all linkers that this attachment is linked
     */
    const linkers = AttachmentService.getLinkersForAttachment(attachment._id);
    const allowedTypes = filterOptions.map(o => o.value);
    const allowedSubTypes = filterOptions.map(o => o.secondValue);

    // If the filter is set to nothing and this attachment has no linkers then include this attachment
    if (allowedTypes.some(type => type === 'NOTHING') && linkers.length === 0) return true;

    // For each linker that this attachment is linked to, check if the linker's parent type matches any of the allowedTypes
    for (const linker of linkers) {
        // Does this linker's parent type type exists in the allowedTypes?
        let isAllowed = allowedTypes.some(allowedType => allowedType === linker.parentType);

        // If the linker is currently being allowed check if it has a subType and then check if sub type exists in allowedSubTypes
        if (isAllowed && linker.parentSubType) isAllowed = allowedSubTypes.some(t => t === linker.parentSubType);

        // If the current linker is allowed then break early because we want to incude it
        if (isAllowed === true) {
            include = isAllowed;
            break;
        }
    }

    return include;
};

/**
 *  Sorts the passed attachments based of the current filter sort by options
 * TODO DUE TO A STUPID AURELIA BUG WE HAVE TO SET FILTERED ATTACHMENTS TO A NEW INSTANCE OF THE ARRAY THUS CAUSING ALL BINDINGS TO FIRE AGIAN -_- CHECK AGAIN WHEN SQUEEGEE NEXT IS MERGED
 * @param {Array<Attachment>} filteredAttachments
 * @param {Filter<IAttachmentsFilterItemDictionary>} filter
 * @return {*}  {Array<Attachment>}
 */
export const sortList = (filteredAttachments: Array<Attachment>, filter: Filter<IAttachmentsFilterItemDictionary>): Array<Attachment> => {
    const sortOption = filter.filterOptions.sortBy.selectedOption;

    if (sortOption) {
        const key = sortOption.propertyKey;
        if (key) {
            return filteredAttachments.slice(0).sort((a, b) => {
                const aValue = a[key];
                const bValue = b[key];
                if (aValue && bValue) {
                    if (typeof aValue === 'number' && typeof bValue === 'number')
                        return sortOption.reverse ? bValue - aValue : aValue - bValue;
                    else if (typeof aValue === 'string' && typeof bValue === 'string')
                        return sortOption.reverse ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
                }
                return 0;
            });
        }
    }

    return filteredAttachments;
};

/**
 * Filters the passed attachments and modifes the existing filteredAttachments without creating a new instance
 * @param {Array<Attachment>} attachments
 * @param {Array<Attachment>} filteredAttachments
 * @param {string} searchText
 * @param {Filter<IAttachmentsFilterItemDictionary>} filter
 */
export const filterList = (
    attachments: Array<Attachment>,
    filteredAttachments: Array<Attachment>,
    searchText: string,
    filter: Filter<IAttachmentsFilterItemDictionary>
) => {
    try {
        // Remove any currently filtered attachments that aren't in the global attachments array
        if (filteredAttachments)
            for (let i = filteredAttachments.length - 1; i >= 0; i--) {
                const filteredAttachment = filteredAttachments[i];
                if (!attachments.some(a => a._id === filteredAttachment._id)) filteredAttachments.splice(i, 1);
            }

        for (const attachment of attachments) {
            let include = true;
            // Check if we should include this attachment in the filtered attachments array
            if (searchText && searchText.length) include = nameMatch(attachment, searchText);

            // Only filter if include is set true because a search result has been found
            if (
                include === true &&
                filter.filterOptions.attachedTo.selectedOptions &&
                filter.filterOptions.attachedTo.selectedOptions.length
            )
                include = attachedToFilter(attachment, filter);

            const currentFilteredIndex = filteredAttachments.findIndex(a => a._id === attachment._id);
            // If include is true and its not currently in the filtered attachments array then add it
            if (include && currentFilteredIndex === -1) {
                filteredAttachments.push(attachment);
                // If include is false and the attachment exists in the filtered attachments array then remove it
            } else if (!include && currentFilteredIndex > -1) {
                filteredAttachments.splice(currentFilteredIndex, 1);
            }
        }
    } catch (error) {
        Logger.error('Unable to filter & sort attachments');
        throw error;
    }
};

export type AttachmentFilterOption = IOption<StoredObjectResourceTypes | 'NOTHING', keyof Attachment, TransactionType>;
export type AttachmentsSortOptionValues =
    | 'DATE_NEWEST_OLDEST'
    | 'DATE_OLDEST_NEWEST'
    | 'NAME_A_Z'
    | 'NAME_Z_A'
    | 'FILE_TYPE_A_Z'
    | 'FILE_TYPE_Z_A';
export type AttachmentSortOption = IOption<AttachmentsSortOptionValues, keyof Attachment>;

export interface IAttachmentsFilterItemDictionary extends FilterItemDictionary {
    sortBy: IFilterItem<AttachmentSortOption>;
    attachedTo: IFilterItem<AttachmentFilterOption>;
}

/**
 *  Attachments filter menu model.
 *  Used for ui filter menus
 * @return {*}  {Filter<IAttachmentsFilterItemDictionary>}
 */
export const filterMenu = (): Filter<IAttachmentsFilterItemDictionary> => {
    const sortByOptions: Array<AttachmentSortOption> = [
        { name: 'sorting.by-newest-oldest', value: 'DATE_NEWEST_OLDEST', propertyKey: 'uploadDate', reverse: true },
        { name: 'sorting.by-oldest-newest', value: 'DATE_OLDEST_NEWEST', propertyKey: 'uploadDate' },
        { name: 'sorting.by-alphabetical-a-z', value: 'NAME_A_Z', propertyKey: 'name' },
        { name: 'sorting.by-alphabetical-z-a', value: 'NAME_Z_A', propertyKey: 'name', reverse: true },
        { name: 'sorting.by-file-type-a-z', value: 'FILE_TYPE_A_Z', propertyKey: 'mimeType' },
        { name: 'sorting.by-file-type-z-a', value: 'FILE_TYPE_Z_A', propertyKey: 'mimeType', reverse: true },
    ];

    const attachedOptions: Array<AttachmentFilterOption> = [
        { name: 'general.customer', value: 'customers' },
        { name: 'general.job', value: 'joboccurrences' },
        { name: 'general.quote', value: 'quotes' },
        { name: 'general.invoice', value: 'transactions', secondValue: TransactionType.Invoice },
        { name: 'general.expense', value: 'transactions', secondValue: TransactionType.Expense },
        { name: 'general.users', value: 'accountuser' },
        { name: 'general.nothing', value: 'NOTHING' },
    ];

    const items: IAttachmentsFilterItemDictionary = {
        sortBy: {
            type: 'sortBy',
            name: 'filters.sort-by',
            optionNameKey: 'name',
            optionValueKey: 'value',
            options: sortByOptions,
            selectedOption: sortByOptions[0],
        },
        attachedTo: {
            type: 'filter',
            name: 'general.attached-to',
            optionNameKey: 'name',
            optionValueKey: 'value',
            options: attachedOptions,
        },
    };
    return new Filter<IAttachmentsFilterItemDictionary>(items);
};
