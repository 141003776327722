import type { Customer, Transaction } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { TransactionDialog } from '../../Transactions/Components/TransactionDialog';
import { TransactionService } from '../../Transactions/TransactionService';
export class AutomaticPaymentsPending {
    @bindable customer: Customer;
    customerChanged() {
        this.load();
    }

    pendingPayments?: Array<Transaction> = [];
    private dataRefreshedSub: Subscription;
    attached() {
        this.dataRefreshedSub = DataRefreshedEvent.subscribe(
            (event: DataRefreshedEvent) => event.hasAnyType('transactions') && this.load()
        );
    }

    load() {
        this.pendingPayments?.slice(0);
        this.pendingPayments = TransactionService.getCustomerPendingAutomaticTransactions(this.customer._id);
    }

    detached() {
        if (this.dataRefreshedSub) this.dataRefreshedSub.dispose();
    }

    view(transaction: Transaction) {
        new TransactionDialog(transaction).show();
    }
}
