import type { Attachment, StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { FileUploadDialog } from '../Dialogs/FileUploadDialog';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { UploadItemDialog } from '../Upload/Dialogs/UploadItemDialog';
import { openSystemBrowser } from '../Utilities';
import StoredObjectUtils from '../utils/StoredObjectUtils';
import { AttachmentService } from './AttachmentService';
import { AttachmentUploadItem } from './AttachmentUploadItem';
import { AttachmentDetailsDialog } from './Dialogs/AttachmentDetailsDialog';

type Action = { icon: string; label: TranslationKey; action: (attachment: Attachment) => void };

export class AttachmentActions {
    public static async rename(attachment: Attachment) {
        const dialog = new TextDialog(
            'dialogs.attachment-rename',
            'dialogs.attachment-rename-input',
            attachment.name,
            'dialogs.attachment-rename-input',
            value => {
                if (value && value.trim()) return true;
                else return 'validation.name-required';
            }
        );
        const newName = await dialog.show();

        if (!dialog.cancelled && newName && newName.trim()) {
            attachment.name = newName.trim();
            await AttachmentService.update(attachment);
        }
    }

    public static toggleIsPublic(attachment: Attachment, isPublic: boolean) {
        attachment.isPublic = isPublic;
        attachment.thumbnail = attachment.isPublic ? AttachmentService.getPublicUrl(attachment) : undefined;
        AttachmentService.update(attachment);
    }

    public static async download(attachment: Attachment) {
        const oneTimeUrl = await AttachmentService.getLinkUrl(attachment);
        if (!oneTimeUrl) return;

        return openSystemBrowser(oneTimeUrl);
    }

    public static async viewDetails(attachment: Attachment) {
        const dialog = new AttachmentDetailsDialog(attachment._id);
        await dialog.show();
    }

    public static async addNew(parent?: StoredObject, isPublic?: boolean): Promise<boolean> {
        // Only allow upload when online
        if (Api.isConnected) {
            //Max file size 50mb
            const dialog = new FileUploadDialog([], undefined, AttachmentService.MAX_FILE_SIZE, true);
            const files = await dialog.show();

            if (!dialog.cancelled && files && files.length) {
                for (const file of files) {
                    new AttachmentUploadItem(
                        'NEW',
                        file,
                        undefined,
                        parent ? parent._id : undefined,
                        parent ? AttachmentService.getParentType(parent) : undefined,
                        parent ? AttachmentService.getParentSubType(parent) : undefined,
                        isPublic
                    ).addToQueue();
                }
                return true;
            }
        } else {
            new NotifyUserMessage('attachments.must-be-online-to-upload');
        }
        return false;
    }

    public static async replace(attachment: Attachment): Promise<boolean> {
        if (!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner'])) {
            Logger.error('Permission Denied: User tried to replace an attachment');
            return false;
        }

        if (Api.isConnected) {
            //Max file size 50mb
            const dialog = new FileUploadDialog([], undefined, AttachmentService.MAX_FILE_SIZE, false);
            const files = await dialog.show();

            if (!dialog.cancelled && files && files.length) {
                const file = files[0];
                if (file) {
                    const linkers = AttachmentService.getLinkersForAttachment(attachment._id);

                    const linker = linkers.length === 1 ? linkers[0] : undefined;

                    const dialog = new UploadItemDialog(
                        'attachments.replace',
                        new AttachmentUploadItem(
                            'REPLACE',
                            file,
                            attachment._id,
                            linker?.parentId,
                            linker?.parentType,
                            linker?.parentSubType
                        )
                    );

                    await dialog.show();
                    if (!dialog.cancelled) {
                        new NotifyUserMessage('attachments.replace-complete');
                        return true;
                    }
                }
            }
        } else {
            new NotifyUserMessage('attachments.must-be-online-to-replace');
        }
        return false;
    }

    public static async delete(attachment: Attachment): Promise<boolean> {
        try {
            if (!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner'])) throw new Error('Permission Denied');

            const prompt = new Prompt('attachments.confirm-delete-attachment', 'attachments.confirm-delete-attachment-description', {
                okLabel: 'general.delete',
                localisationParams: { attachmentName: attachment.name },
            });

            await prompt.show();

            if (!prompt.cancelled) {
                await AttachmentService.delete(attachment);
                return true;
            }
        } catch (error) {
            new NotifyUserMessage('attachments.unable-to-delete');
        }
        return false;
    }

    /**
     * Returns the available actions for an attachment when parent is passed more actions are available
     * @param item
     * @param attachment
     * @returns
     */
    public static getMenuActions(parent?: StoredObject): Array<Action> {
        const actions: Array<Action> = [
            { icon: 'cloud_download', label: 'general.download', action: attachment => AttachmentActions.download(attachment) },
        ];

        if (ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) {
            if (parent) {
                actions.push({
                    icon: 'remove_circle_outline',
                    label: 'general.unattach',
                    action: attachment => AttachmentActions.unattach(parent, attachment),
                });
            }
            actions.push(
                { icon: 'edit', label: 'general.rename', action: attachment => AttachmentActions.rename(attachment) },
                { icon: 'delete', label: 'general.delete', action: attachment => AttachmentActions.delete(attachment) }
            );
        }

        return actions;
    }

    public static async unattach(item: StoredObject, attachment: Attachment): Promise<{ cancelled: boolean }> {
        const model = StoredObjectUtils.getUIModelForStoredObject(item);

        const prompt = new Prompt('attachments.unattach-confirm', `attachments.unattach-confirm-description`, {
            okLabel: 'general.unattach',
            localisationParams: {
                attachmentName: attachment.name,
                context: `${model.title}, ${model.description}`,
            },
        });

        await prompt.show();

        if (!prompt.cancelled) {
            await AttachmentService.remove(item._id, attachment);
            return { cancelled: false };
        }

        return { cancelled: true };
    }
}
