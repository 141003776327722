import type { Customer } from '@nexdynamic/squeegee-common';
import { sortByStringProperty, wait } from '@nexdynamic/squeegee-common';
import type { Source } from '@stripe/stripe-js';
import { bindable, computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import AutomaticPaymentsActions from '../../AutomaticPayments/AutomaticPaymentsActions';
import { getProviderSourceLink } from '../../Customers/Components/getProviderSourceLink';
import { Data } from '../../Data/Data';
import { Prompt } from '../../Dialogs/Prompt';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { openSystemBrowser } from '../../Utilities';
import type { AutomaticPaymentProvider } from '../AutomaticPaymentsService';
import { AutomaticPaymentsService } from '../AutomaticPaymentsService';
import type { TCardWithActions } from './TCardWithActions';

export class CardPaymentsForm {
    @bindable customer: Customer;
    private _dataChangedEvent?: Subscription;

    protected cardsOnFileWithActions?: Array<TCardWithActions>;
    protected stripe: AutomaticPaymentProvider | undefined;
    async customerChanged() {
        this.load();
        this.initialiseCardsWithActions();
    }
    private async initialiseCardsWithActions() {
        this.cardsOnFileWithActions = [];
        if (!this.customer.externalIds?.stripe) return;

        const cardsOnFile =
            (
                await Api.get<Array<{ id: string; card: Source.Card }>>(
                    null,
                    `/api/payments/stripe/cards-on-file/${this.customer.externalIds?.stripe}`
                )
            )?.data || [];
        if (!cardsOnFile.length) return;

        this.cardsOnFileWithActions = cardsOnFile.map(paymentMethod => {
            const cardWithActions = {} as TCardWithActions;
            cardWithActions.card = paymentMethod.card;
            cardWithActions.id = paymentMethod.id;
            cardWithActions.expiry = moment(`${paymentMethod.card.exp_year}-${`0${paymentMethod.card.exp_month}`.slice(-2)}-01`).endOf(
                'month'
            );
            cardWithActions.expired = !cardWithActions.expiry.isValid() || cardWithActions.expiry.isBefore(moment(), 'day');
            cardWithActions.sort = cardWithActions.expiry.format('YYYYMM');
            return cardWithActions;
        });

        Logger.info('Cards: ', this.cardsOnFileWithActions);

        this.configureCardActions(true);
    }

    protected configureCardActions(sort: boolean) {
        for (const cardWithActions of this.cardsOnFileWithActions || []) {
            cardWithActions.actions = [];
            cardWithActions.default = cardWithActions.id === this.customer.paymentProviderMetaData?.stripe?.sourceOrMandateId;
            if (!cardWithActions.expired && !cardWithActions.default) {
                cardWithActions.actions.push({
                    icon: 'push_pin',
                    label: 'payments.set-default-card-on-file',
                    handler: () => this.setDefaultCard(cardWithActions),
                });
            }

            cardWithActions.actions.push({
                icon: 'delete_forever',
                label: 'payments.remove-card-on-file',
                handler: () => this.removeSavedCard(cardWithActions),
            });
        }
        if (sort) this.sortCardsWithActions();
    }

    protected setDefaultCard(cardWithActions: TCardWithActions): void {
        const card = cardWithActions.card;
        if (!this.customer.paymentProviderMetaData) this.customer.paymentProviderMetaData = {};
        this.customer.paymentProviderMetaData.stripe = {
            sourceOrMandateId: cardWithActions.id,
            cardBrand: card.brand || '',
            cardExpMonth: (card.exp_month || '').toString(),
            cardExpYear: (card.exp_year || '').toString(),
            cardDescription: card.description || '',
            cardFunding: card.funding || '',
            cardIssuer: card.issuer,
            cardLast4: card.last4 || '',
            status: 'active',
        };
        Data.put(this.customer);
        for (const c of this.cardsOnFileWithActions || []) {
            c.default = false;
        }
        cardWithActions.default = true;
        this.configureCardActions(false);
        this.load();
    }
    protected sortCardsWithActions() {
        this.cardsOnFileWithActions?.sort((a, b) => (a.default ? -1 : b.default ? 1 : sortByStringProperty(a, b, 'sort')));
    }
    protected method = ApplicationState.localise('payments.card-payments');

    protected applicationState = ApplicationState;

    protected providers?: Array<AutomaticPaymentProvider>;

    protected async removeSavedCards() {
        const areYouSure = new Prompt('general.confirm', 'payments.remove-cards-on-file-confirm', {
            okLabel: 'payments.remove-cards-on-file-confirm-button',
        });
        await areYouSure.show();
        if (areYouSure.cancelled) return;

        new LoaderEvent(true, true, 'loader.removing-all-cards-on-file-for-customer');

        try {
            const result = await Api.delete<{ success: boolean; error?: string }>(
                null,
                `/api/payments/stripe/cards-on-file/${this.customer.externalIds?.stripe}`
            );
            if (!result?.data.success) throw 'Unable to remove the cards on file for this customer.';

            new Prompt('general.success', 'payments.remove-cards-on-file-success', { cancelLabel: '' }).show();

            if (this.customer.paymentProviderMetaData) {
                this.customer.paymentProviderMetaData.stripe = undefined;
                Data.put(this.customer);
            }
        } catch (error) {
            new Prompt('general.failed', 'payments.remove-cards-on-file-failure', { cancelLabel: '' }).show();
        }

        await this.initialiseCardsWithActions();
        await this.load();
        new LoaderEvent(false);
    }

    protected async removeSavedCard(cardWithActions: TCardWithActions) {
        const areYouSure = new Prompt('general.confirm', 'payments.remove-card-on-file-confirm', {
            okLabel: 'payments.remove-card-on-file-confirm-button',
        });
        await areYouSure.show();
        if (areYouSure.cancelled) return;
        new LoaderEvent(true, true, 'loader.removing-card-on-file-for-customer');
        try {
            const result = await Api.delete<{ success: boolean; error?: string }>(
                null,
                `/api/payments/stripe/cards-on-file/${this.customer.externalIds?.stripe}/${cardWithActions.id}`
            );
            if (!result?.data.success) throw 'Unable to remove the selected card on file from this customer.';

            new LoaderEvent(false);
            new Prompt('general.success', 'payments.remove-card-on-file-success', { cancelLabel: '' }).show();
            this.cardsOnFileWithActions = this.cardsOnFileWithActions?.filter(c => c.id !== cardWithActions.id);
            if (this.customer.paymentProviderMetaData?.stripe?.sourceOrMandateId === cardWithActions.id) {
                this.customer.paymentProviderMetaData.stripe = undefined;
                Data.put(this.customer);
            }
            this.configureCardActions(false);
            await this.load();
        } catch (error) {
            new Prompt('general.failed', 'payments.remove-card-on-file-failure', { cancelLabel: '' }).show();
            new LoaderEvent(false);
        }
    }

    async load() {
        this.providers = await AutomaticPaymentsService.getCardProviders(this.customer);
        this.stripe = this.providers.find(x => x.name === 'stripe');
    }

    configure() {
        AutomaticPaymentsActions.configure();
    }

    @computedFrom('customer', 'applicationState')
    get hasProvider() {
        return ApplicationState.hasCardProvider();
    }

    addCardUsingStripeDashboard = async () => {
        try {
            new LoaderEvent(true, true, 'loader.redirecting-to-stripe-dashboard');
            if (!this.stripe) return new NotifyUserMessage('payments.card-provider-not-available');

            const stripeCustomerIdRequest = await Api.get<{ stripeCustomerId: string }>(
                null,
                `/api/payments/get-stripe-customer-id/${this.customer._id}`
            );

            const stripeCustomerId = stripeCustomerIdRequest?.data.stripeCustomerId;
            if (!stripeCustomerId) return new NotifyUserMessage('payments.stripe-customer-not-found');

            await wait(1000);
            const customer = await Data.get<Customer>(this.customer._id);
            if (!customer) return new NotifyUserMessage('payments.stripe-customer-not-found');

            if (customer.externalIds?.stripe !== stripeCustomerId) {
                customer.externalIds ??= {};
                customer.externalIds.stripe = stripeCustomerId;
                await Data.put(customer);
            }

            const stripeUrl = getProviderSourceLink(customer, 'stripe');
            openSystemBrowser(stripeUrl);
        } finally {
            new LoaderEvent(false);
        }
    };

    async attached() {
        this._dataChangedEvent = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (!event.updatedObjects[this.customer._id]) return;
            await this.load();
            await this.initialiseCardsWithActions();
        });
    }

    async detached() {
        this._dataChangedEvent?.dispose();
    }
}
