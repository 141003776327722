import { bindable, computedFrom } from 'aurelia-framework';
import type { Plan } from '../../Account/AccountBillingService';
import './plan.scss';

export class PlanCustomElement {
    @bindable plan?: Plan;
    @bindable selectable: boolean;
    @bindable showDescription = true;

    @computedFrom('plan')
    get planBackground() {
        if (!this.plan) return;
        if (this.plan.name && this.plan.name.toLocaleLowerCase().indexOf('advanced') > -1) {
            return `background-color: #FFE53B; background-image: linear-gradient(205deg, #FFE53B 0%, #FF2525 74%);`;
        }
        return `background-color: #21D4FD; background-image: linear-gradient(341deg, #21D4FD 0%, #B721FF 100%)`;
    }
}
