import { inject } from 'aurelia-framework';
import type { RouterConfiguration } from 'aurelia-router';
import { Router } from 'aurelia-router';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { GlobalFlags } from '../GlobalFlags';
import { routes } from './Routes';

@inject(Router)
export class Account {
    private constructor(private router: Router) { }

    public attached() {
        new ViewResizeEvent();
    }

    public async configureRouter(config: RouterConfiguration, router: Router) {
        if (GlobalFlags.isHttp) {
            config.options.pushState = true;
            config.options.root = '/';
        }
        await router.configure(config.map(routes));
        this.router = router;
        this.router.refreshNavigation();
    }
}
