// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-summary {
  padding: 16px;
  width: 100%;
}
.users-summary__header {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.users-summary__header div:first-child {
  flex: 1;
}
.users-summary__body {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/users-summary.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACI,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AAER;AADQ;EACI,OAAA;AAGZ;AAAI;EACI,aAAA;AAER","sourcesContent":[".users-summary {\n    padding: 16px;\n    width: 100%;\n    &__header {\n        padding: 8px 16px;\n        font-weight: bold;\n        font-size: 18px;\n        display: flex;\n        align-items: center;\n        div:first-child {\n            flex: 1;\n        }\n    }\n    &__body {\n        padding: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
