import type { ICustomerSubscriptionPlanSummary, ICustomerSubscriptionSummary, ICustomerSummary } from '@nexdynamic/squeegee-common';
import { computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AccountActions } from '../../Account/AccountActions';
import type { Plan } from '../../Account/AccountBillingService';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { GlobalFlags } from '../../GlobalFlags';
import { Logger } from '../../Logger';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { UserService } from '../../Users/UserService';
import './current-plan.scss';

@inject(Router)
export class CurrentPlanCustomElement {
    account?: ICustomerSummary;
    loading = true;
    devMode = ApplicationState.stateFlags.devMode;
    constructor(private router: Router) { }

    async bind() {
        this.loading = true;
        this.account = await AccountBillingService.getAccount();
        this.loading = false;
    }

    @computedFrom('account')
    get plan(): ICustomerSubscriptionPlanSummary | undefined {
        if (this.subscription) return this.subscription.plans[0];
    }
    @computedFrom('account')
    get uiPlan(): Plan | undefined {
        if (!this.plan) return;
        return {
            name: this.plan.nickname,
            periods: [],
        };
    }

    @computedFrom('account')
    get subscription(): ICustomerSubscriptionSummary | undefined {
        if (this.account?.subscriptions && this.account.subscriptions[0]) return this.account.subscriptions[0];
    }

    @computedFrom('account')
    get totalPer() {
        return ApplicationState.localise('account.total-per-period', { period: this.billingPeriod });
    }

    @computedFrom('account')
    get billingPeriod() {
        return this.plan?.isMonthly
            ? ApplicationState.localise('account.billing-month')
            : ApplicationState.localise('account.billing-year');
    }

    @computedFrom('account')
    get activeUsers() {
        const activeUsers = UserService.getActiveUsers().length;
        return ApplicationState.localise('account.active-users', { count: activeUsers.toFixed(0) });
    }

    @computedFrom('account')
    get planStatus() {
        if (!this.plan) return;
        let status = ApplicationState.subscription?.status || '';
        switch (status) {
            case 'overdue': {
                status = 'payment failed, retrying';
                break;
            }
            case 'unpaid': {
                status = 'unpaid, please make payment';
                break;
            }
        }
        return status;
    }

    @computedFrom('account')
    get planDescription() {
        return ApplicationState.localise('account.plan-description', {
            planName: this.plan?.nickname || 'Unknown Plan'
        });
    }

    cancelSubscription() {
        if (this.subscription) {
            AccountActions.cancelSubscription(this.subscription);
        } else {
            Logger.error('Unable to cancel subscription');
        }
    }

    manageSubscription() {
        // On ios redirect to website
        if (GlobalFlags.isAppleMobileApp) {
            if (!RethinkDbAuthClient.session) return;
            const { key, value } = RethinkDbAuthClient.session;
            window.location.href = `${Api.apiEndpoint}/account/subscription?session-key=${key}&session-value=${value}&dataEmail=${ApplicationState.dataEmail}&redirectOnSuccessUrl=web+squeegee://account/subscription`;
        } else {
            this.router.navigate('/account/subscription');
        }
    }

    async reactivate() {
        if (this.subscription?.id) {
            await AccountActions.reactivatePlan(this.subscription.id);
        }
    }

    get unpaid() {
        return ApplicationState.subscription.status === 'unpaid';
    }
}
