import type { ISession, MultiFactorAuthInfo } from '@nexdynamic/squeegee-common';
import { validateEmailSecondFactorAuth } from './validateEmailSecondFactorAuth';
import { validateSmsSecondFactorAuth } from './validateSmsSecondFactorAuth';
import { validateTotpSecondFactorAuth } from './validateTotpSecondFactorAuth';

export const validateSecondFactor = async (type: MultiFactorAuthInfo['type'], session: ISession): Promise<ISession | undefined> => {
    switch (type) {
        case 'totp': return validateTotpSecondFactorAuth(session);
        case 'sms': return validateSmsSecondFactorAuth(session);
        case 'email': return validateEmailSecondFactorAuth(session);
    }
};
