import { wait } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import type { PaymentDetails } from '../../Account/AccountBillingService';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { SubscriptionApi } from '../../Server/SubscriptionApi';
import { getRedirectOnSuccessUrl } from '../../getRedirectOnSuccessUrl';
import './payment-form.scss';
export class PaymentFormCustomElement {
    paymentDetails?: PaymentDetails;
    editCard = false;
    loading = true;

    @bindable onSubmit?: (paymentDetails?: PaymentDetails) => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) hasPaymentDetails?: boolean = false;
    @bindable isSqueegeePayment: boolean;

    async bind() {
        await this.loadData();
    }

    @computedFrom('paymentDetails.card')
    get card() {
        return this.paymentDetails?.card;
    }
    toggleUpdateCard() {
        this.editCard = !this.editCard;
    }

    @computedFrom('editCard', 'paymentDetails', 'loading')
    get showStripeForm() {
        if (this.editCard || (this.loading === false && !this.paymentDetails)) return true;
        else return false;
    }
    update = async (confirmed: boolean) => {
        try {
            if (confirmed) {
                this.editCard = false;
                await wait();
                new LoaderEvent(true, true);
                await SubscriptionApi.checkAndUpdateDefaultCard();
                await this.loadData();

                if (this.onSubmit) await this.onSubmit(this.paymentDetails);
                const redirect = getRedirectOnSuccessUrl();
                if (redirect) window.location.href = redirect;
                new NotifyUserMessage('account.payment-details-saved');
            }
        } catch (error) {
            new NotifyUserMessage('account.payment-details-failed');
            Logger.error('unable to update card details', error);
        } finally {
            new LoaderEvent(false);
        }
    };

    async loadData() {
        this.loading = true;
        this.paymentDetails = await AccountBillingService.getPaymentDetails();
        this.hasPaymentDetails = Boolean(this.paymentDetails);
        requestAnimationFrame(() => {
            this.loading = false;
        });
    }
}
