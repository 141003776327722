import type { MultiFactorAuthInfo, StandardApiResponseWithData } from '@nexdynamic/squeegee-common';
import { Api } from '../../Server/Api';


export const getCurrentTwoFactor = async (): Promise<MultiFactorAuthInfo> => {
    const methodsRequest = await Api.get<StandardApiResponseWithData<MultiFactorAuthInfo>>(null, '/api/authentication/multi-factor');
    if (!methodsRequest?.data?.success || !methodsRequest?.data?.data)
        return { type: 'none' };

    return methodsRequest?.data?.data || { type: 'none' };
};
