// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripe-payment #stripe-container {
  padding: 16px;
  border-radius: 6px;
  background-color: #fafafa;
}
.stripe-payment__submit {
  display: flex;
  justify-content: flex-end;
}
.stripe-payment__submit button {
  min-width: 140px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/SqueegeeSubscriptionPaymentCustomElement.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,kBAAA;EACA,yBAAA;AAAR;AAEI;EACI,aAAA;EACA,yBAAA;AAAR;AACQ;EACI,gBAAA;AACZ","sourcesContent":[".stripe-payment {\n    #stripe-container {\n        padding: 16px;\n        border-radius: 6px;\n        background-color: #fafafa;\n    }\n    &__submit {\n        display: flex;\n        justify-content: flex-end;\n        button {\n            min-width: 140px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
