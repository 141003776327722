import type { Attachment, StoredObject } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { AttachmentActions } from '../AttachmentActions';
import './attachment-menu.scss';

export class AttachmentMenu {
    @bindable attachment: Attachment;
    @bindable parent?: StoredObject;

    @computedFrom('attachment', 'parent')
    get menuItems() {
        if (!this.attachment) return;
        return AttachmentActions.getMenuActions(this.parent);
    }
}
