import type { StandardApiResponse, StandardApiResponseWithData, TranslationKey } from '@nexdynamic/squeegee-common';
import { toDataURL } from 'qrcode';
import { HtmlDialog } from '../../Dialogs/HtmlDialog';
import { Prompt } from '../../Dialogs/Prompt';
import { TextDialog } from '../../Dialogs/TextDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { GlobalFlags } from '../../GlobalFlags';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { t } from '../../t';

export const enableTotpTwoFactor = async () => {
    try {
        new LoaderEvent(true, true);
        const start = await Api.post<StandardApiResponseWithData<{ url: string }>>(
            null,
            '/api/authentication/multi-factor/totp/register-start',
            {
                label: RethinkDbAuthClient.session?.email || 'Signin',
            }
        );

        new LoaderEvent(false);
        if (!start?.data?.success) {
            const error = start?.data?.success === false ? start?.data?.error : 'general.unknown-error-occurred';
            await new Prompt('general.error', error as TranslationKey, { cancelLabel: '' }).show();
            return;
        } else {
            const secretDataUrl = await toDataURL(start.data.data.url, { errorCorrectionLevel: 'H', margin: 0, scale: 10 });

            const qrcodeDialog = new HtmlDialog(
                'Scan Authenticator QR Code' as TranslationKey,
                `<center style="margin-top: 50px;">
                    <img src="${secretDataUrl}" style="max-width:200px;"/>
                    <br />
                    <br />
                    <br />
                    ${GlobalFlags.isMobile ? `<a href="${start.data.data.url}">Add to Google Authenticator</a>` : ''}
                <center>`,
                true
            );

            qrcodeDialog.done = () => qrcodeDialog.ok();
            qrcodeDialog.doneText = 'general.continue';

            await qrcodeDialog.show();

            if (qrcodeDialog.cancelled) return;

            let attempts = 3;
            const codeTitle = t('two-factor.dialog-totp-code');
            const codeDescription = t('two-factor.dialog-totp-code-validate-description');
            while (attempts--) {
                const codeDialog = new TextDialog(
                    codeTitle,
                    codeDescription,
                    '',
                    '123456' as TranslationKey,
                    v => (v?.length !== 6 ? 'two-factor.dialog-code-requirements' : true),
                    undefined,
                    undefined,
                    'two-factor.dialog-check-code',
                    undefined,
                    undefined,
                    6
                );
                const code = await codeDialog.show();
                if (codeDialog.cancelled) return;

                new LoaderEvent(true, true);
                const end = await Api.post<StandardApiResponse>(null, '/api/authentication/multi-factor/totp/register-complete', { code });

                new LoaderEvent(false);
                if (!end?.data?.success) {
                    const error = end?.data?.success === false ? end?.data?.error : 'general.unknown-error-occurred';
                    await new Prompt('general.error', error as TranslationKey, { cancelLabel: '' }).show();
                } else {
                    new Prompt('two-factor.dialog-totp-success-title', 'two-factor.dialog-totp-success-description', {
                        cancelLabel: '',
                    }).show();
                    return;
                }
            }
        }
    } catch (error) {
        new LoaderEvent(false);
        await new Prompt('general.error', 'general.unknown-error-occurred', { cancelLabel: '' }).show();
        return;
    }
};
