import type { StoredObjectResourceTypes, TransactionSubType, TransactionType} from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import { Logger } from '../Logger';
import { UploadItem } from '../Upload/UploadItem';
import { AttachmentService } from './AttachmentService';

export class AttachmentUploadItem extends UploadItem {
    private uploadManager: { cancel: (msg: string) => void | undefined } = {
        cancel: () => {
            Logger.info('Cancel request was called but was not bound');
        },
    };

    constructor(
        protected action: 'NEW' | 'REPLACE',
        protected file: File,
        protected fileId: string = uuid(),
        protected parentId?: string,
        protected parentType?: StoredObjectResourceTypes,
        protected parentSubType?: '' | TransactionType | TransactionSubType,
        protected isPublic?: boolean
    ) {
        super('attachment', file);
    }

    public async upload() {
        try {
            const imageData = await AttachmentService.getImageData(this.file, this.parentType === 'accountuser' || this.parentType === 'tags');

            if (this.action === 'NEW') {
                await AttachmentService.upload(
                    imageData?.image || this.file,
                    this.fileId,
                    imageData?.thumbnail?.data,
                    imageData?.thumbnail?.dimensions,
                    this.parentId,
                    this.parentType,
                    this.parentSubType,
                    this.isPublic,
                    this.progressHandler,
                    this.uploadManager
                );
            } else if (this.action === 'REPLACE') {
                await AttachmentService.replace(
                    imageData?.image || this.file,
                    this.fileId,
                    imageData?.thumbnail?.data,
                    imageData?.thumbnail?.dimensions,
                    this.isPublic,
                    this.progressHandler,
                    this.uploadManager
                );
            }
            this.remove();
        } catch (error) {
            if (error && error.message === 'UPLOAD_CANCELED') return this.remove();
            this.errorHandler(error);
        }
    }

    public cancel = () => {
        if (this.uploadManager.cancel) this.uploadManager.cancel('UPLOAD_CANCELED');
    };
}
