// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-summary {
  padding: 16px;
  width: 100%;
}
.delete-summary__header {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.delete-summary__header div:first-child {
  flex: 1;
}
.delete-summary__header button {
  background-color: darkred !important;
}
.delete-summary__body {
  padding: 16px;
}
.delete-summary .pending {
  color: darkred;
  font-weight: bold;
  background-color: yellow;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/delete-account.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACI,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AAER;AADQ;EACI,OAAA;AAGZ;AADQ;EACI,oCAAA;AAGZ;AAAI;EACI,aAAA;AAER;AAAI;EACI,cAAA;EACA,iBAAA;EACA,wBAAA;AAER","sourcesContent":[".delete-summary {\n    padding: 16px;\n    width: 100%;\n    &__header {\n        padding: 8px 16px;\n        font-weight: bold;\n        font-size: 18px;\n        display: flex;\n        align-items: center;\n        div:first-child {\n            flex: 1;\n        }\n        button {\n            background-color: darkred !important;\n        }\n    }\n    &__body {\n        padding: 16px;\n    }\n    .pending {\n        color: darkred;\n        font-weight: bold;\n        background-color: yellow;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
