import type { ICustomerSubscriptionPlanSummary, ICustomerSummary } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import type { Plan, PlanInterval, PlanPeriod } from '../../Account/AccountBillingService';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { ApplicationState } from '../../ApplicationState';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { UserService } from '../../Users/UserService';
import './subscription-form.scss';

export class SubscriptionFormFields {
    // Input coverts value to string
    users = 1;
    planId: string;
    interval: PlanInterval;
    previousPlanId?: string;
    previousUsers?: number;
    requirePayment?: boolean = true;
    constructor(currentPlan?: ICustomerSubscriptionPlanSummary) {
        if (currentPlan) {
            this.previousPlanId = currentPlan.id;
            this.previousUsers = currentPlan.quantity;
            this.interval = currentPlan.isMonthly ? 'month' : 'year';
            this.planId = currentPlan.id;
            this.users = currentPlan.quantity;
        }
    }
}
export class SubscriptionFormCustomElement {
    plans?: Array<Plan>;
    @bindable fields?: SubscriptionFormFields;
    @bindable account?: ICustomerSummary;

    fieldsChanged(newValue?: SubscriptionFormFields, oldValue?: SubscriptionFormFields) {
        //First time set only
        if (!oldValue && newValue) {
            this.setDefaultPlan(newValue);
        }
        if (!this.userCount && newValue) this.userCount = newValue.users.toFixed(0);
    }
    @bindable onSubmit: (fields: SubscriptionFormFields) => void;
    selectedPlan?: Plan;
    selectedPeriod?: PlanPeriod;
    activeUsers?: number;
    _userCount: string;

    hasPaymentDetails = false;

    protected appleApp = !ApplicationState.canShowAccountManagement;
    protected getSupport() {
        ApplicationState.getSupport('No Credit Card - Alternate Trial Verification Request', false);
    }

    get userCount(): string {
        return this._userCount;
    }

    set userCount(value: string) {
        this._userCount = value;
        const count = parseInt(value);
        if (this.fields) {
            this.fields.users = count;
        }
    }

    async bind() {
        this.plans = await AccountBillingService.getSelectablePlans();
        //if no payment required we are only allowing
        if (this.fields && !this.fields?.requirePayment) {
            this.plans = this.plans?.filter(p => {
                return p.name.toLowerCase() === 'advanced';
            });
            this.activeUsers = 1;
        } else {
            this.activeUsers = UserService.getActiveUsers().length;
        }
        if (this.fields) this.setDefaultPlan(this.fields);
    }

    private setDefaultPlan(fields: SubscriptionFormFields) {
        const defaultPlanIndex: number = this.plans?.length === 1 ? 0 : 1;
        if (this.plans && fields?.planId) {
            const currentPlan = this.plans?.find(plan => {
                return Boolean(plan.periods.find(p => p.planId === this.fields?.planId));
            });

            if (!currentPlan) Logger.warn('Current plan was not found in the returned list of plans selecting default');
            this.planSelected(currentPlan || this.plans[defaultPlanIndex]);
        } else if (this.plans) {
            this.planSelected(this.plans[defaultPlanIndex]);
        }
    }

    @computedFrom('plans', 'fields')
    get loading() {
        return Boolean(!this.plans || !this.fields);
    }

    @computedFrom('plans', 'selectedPlan', 'fields.planId')
    get periods(): Array<PlanPeriod> | undefined {
        if (!this.selectedPlan) return;
        return this.selectedPlan.periods.sort((a, b) => {
            return a.interval > b.interval ? 1 : a.interval < b.interval ? -1 : 0;
        });
    }

    submit = () => {
        if (!this.hasPaymentDetails && this.fields?.requirePayment) {
            new NotifyUserMessage('account.payment-details-required');
        } else {
            if (this.onSubmit && this.fields) this.onSubmit(this.fields);
        }
    };

    planSelected(plan: Plan) {
        this.selectedPlan = plan;
        //If an interval has already been set then choose the same interval for the selected plan
        if (this.fields?.interval) {
            const period = plan.periods.filter(p => p.interval === this.fields?.interval)[0];
            this.planPeriodSelected(period);
        } else {
            this.planPeriodSelected(plan.periods.filter(x => x.interval === 'month')[0]);
        }
    }

    planPeriodSelected(period: PlanPeriod) {
        if (!this.fields) return;
        this.selectedPeriod = period;
        this.fields.planId = period.planId;
        this.fields.interval = period.interval;
    }
    @computedFrom('selectedPeriod', 'fields.users')
    get summaryNameFlat() {
        if (!this.selectedPeriod) return;
        return `${this.selectedPlan?.name} (${this.selectedPeriod?.title})`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get summaryDescriptionFlat() {
        if (!this.selectedPeriod) return;
        return `Private hosting and maintenance fee ${this.selectedPeriod.currency}${this.selectedPeriod.additional.toFixed(2)}`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get totalPriceFlat() {
        if (!this.selectedPeriod || !this.fields || !this.selectedPeriod.additional) return;
        return `${this.selectedPeriod.currency}${this.selectedPeriod.additional.toFixed(2)}`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get summaryName() {
        if (!this.selectedPeriod) return;
        return `${this.selectedPlan?.name} (${this.selectedPeriod?.title})`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get summaryDescription() {
        if (!this.selectedPeriod) return;
        return `${this.fields?.users} users ${this.selectedPeriod.currency}${this.selectedPeriod.total.toFixed(2)} per user`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get totalPriceUsers() {
        if (!this.selectedPeriod || !this.fields) return;
        return `${this.selectedPeriod.currency}${(this.selectedPeriod.total * this.fields.users).toFixed(2)}`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get totalPrice() {
        if (!this.selectedPeriod || !this.fields) return;
        return `${this.selectedPeriod.currency}${(this.selectedPeriod.total * this.fields.users + this.selectedPeriod.additional).toFixed(2)}`;
    }

    @computedFrom('selectedPeriod', 'fields.users')
    get totalPriceToday() {
        if (!this.selectedPeriod || !this.fields) return;
        return `${this.selectedPeriod.currency}${(0).toFixed(2)}`;
    }

    @computedFrom('fields.planId', 'fields.previousPlanId', 'fields.users', 'fields.previousUsers')
    get hasChanged() {
        if (!this.fields) return;
        //If they have had no plan before
        if (!this.fields?.previousPlanId) return true;
        // They have changed if
        //If they have switched plan
        if (this.fields?.previousPlanId && this.fields.planId !== this.fields.previousPlanId) return true;
        //If they have changed the users
        if (this.fields?.previousUsers && this.fields.users !== this.fields.previousUsers) return true;
    }

    @computedFrom('loading', 'fields.planId', 'hasChanged')
    get submitDisabled() {
        return !this.hasChanged || this.loading;
    }

    @computedFrom('fields.previousPlanId', 'trialApplied')
    get submitText() {
        const key = this.fields?.previousPlanId
            ? 'account.update-subscription'
            : this.trialApplied
                ? 'account.start-free-trial'
                : 'account.subscribe';
        return ApplicationState.localise(key);
    }

    @computedFrom('account')
    get paymentDetailsText() {
        //Enter your payment details
        // 'account.card-to-charge'
        return ApplicationState.localise('account.enter-payment-details');
    }

    @computedFrom('userCount', 'activeUsers')
    get limitText() {
        if (this.activeUsers !== undefined && this.userCount === this.activeUsers.toFixed(0)) {
            return ApplicationState.localise('account.user-limit-hit', { count: this.activeUsers.toFixed(0) });
        }
    }

    @computedFrom('fields.planId', 'hasChanged')
    get trialApplied() {
        return Boolean(this.selectedPlan?.trialPeriod);
    }
}
