// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-period {
  flex: 1;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 16px;
  min-width: 200px;
}
.plan-period__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}
.plan-period__price {
  font-size: 20px;
}
.plan-period__price small {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/plan-period.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AAER;AAAI;EACI,eAAA;AAER;AADQ;EACI,eAAA;AAGZ","sourcesContent":[".plan-period {\n    flex: 1;\n    border-radius: 8px;\n    border: 1px solid #ccc;\n    padding: 16px;\n    min-width: 200px;\n    &__title {\n        font-size: 18px;\n        font-weight: 700;\n        margin-bottom: 8px;\n    }\n    &__price {\n        font-size: 20px;\n        small {\n            font-size: 14px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
