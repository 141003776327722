// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-overview {
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  display: flex;
}
.account-overview__header {
  flex: 100%;
}
.account-overview__item {
  flex: 1 1 280px;
}
.account-overview__section {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}
.account-overview__section__item {
  display: block;
  width: 100%;
}

@media screen and (max-width: 570px) {
  .account-overview {
    gap: 0;
  }
  .account-overview__section {
    gap: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/Account/Views/AccountOverview.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;AACJ;AAAI;EACI,UAAA;AAER;AAAI;EACI,eAAA;AAER;AAAI;EACI,WAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AAER;AADQ;EACI,cAAA;EACA,WAAA;AAGZ;;AAEA;EAEI;IACI,MAAA;EAAN;EACM;IACI,MAAA;EACV;AACF","sourcesContent":[".account-overview {\n    gap: 16px;\n    flex-direction: row;\n    flex-wrap: wrap;\n    margin: auto;\n    display: flex;\n    &__header {\n        flex: 100%;\n    }\n    &__item {\n        flex: 1 1 280px;\n    }\n    &__section {\n        width: 100%;\n        display: flex;\n        gap: 16px;\n        flex-direction: row;\n        flex-wrap: wrap;\n        margin: auto;\n        &__item {\n            display: block;\n            width: 100%;\n        }\n    }\n}\n\n@media screen and (max-width: 570px) {\n    // TODO Whitespace is still important on mobile improve ux/ui of mobile layout\n    .account-overview {\n        gap: 0;\n        &__section {\n            gap: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
