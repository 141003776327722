import type { Attachment, StoredObject, UserRole } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { Utilities, getAttachmentPublicUrl } from '../../Utilities';
import type { IStoredObjectModel } from '../../utils/StoredObjectUtils';
import { AttachmentActions } from '../AttachmentActions';
import { AttachmentService } from '../AttachmentService';

export class AttachmentDetailsDialog extends CustomDialog<void> {
    protected moreActions: Array<IMenuBarAction> = [];

    protected attachedToItems: Array<StoredObject>;
    protected attachmentUrl: string;

    private _dataRefreshedSub: Subscription;

    protected attachment: Attachment | undefined;
    protected isAllowedToEdit: Array<UserRole> = ['Owner', 'Admin', 'Creator'];
    protected apiEndpoint = Api.apiEndpoint;

    constructor(public attachmentId: string) {
        super('attachmentsDetailsDialog', '../Attachments/Dialogs/AttachmentDetailsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            cssClass: 'details-dialog no-nav-shadow',
        });
    }

    async init() {
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            (event: DataRefreshedEvent) => event.hasAnyType('linkers', 'attachments') && this.loadData()
        );
        this.loadData();
    }

    protected get isPublic() {
        return !!this.attachment?.isPublic;
    }

    protected set isPublic(value: boolean) {
        if (this.attachment) AttachmentActions.toggleIsPublic(this.attachment, value);
    }

    dispose() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
        super.dispose();
    }

    async loadData() {
        this.attachment = Data.get<Attachment>(this.attachmentId);
        if (this.attachment) {
            this.moreActions = this.getMoreActions();
            this.attachedToItems = AttachmentService.getAttachmentParents(this.attachment._id);
            const url = await AttachmentService.getLinkUrl(this.attachment);
            if (url) this.attachmentUrl = url + new Date().getTime();
        } else {
            this.cancel();
        }
    }

    public getMoreActions(): Array<IMenuBarAction> {
        if (!this.attachment) return [];
        return [
            {
                tooltip: 'attachments.replace',
                handler: this._replaceAttachment,
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'attachments.delete',
                handler: this._deleteAttachment,
                roles: ['Owner', 'Admin'],
            },
        ];
    }

    async rename() {
        if (this.attachment) await AttachmentActions.rename(this.attachment);
    }

    protected _replaceAttachment = async () => {
        if (this.attachment) {
            const complete = await AttachmentActions.replace(this.attachment);
            if (complete) this.loadData();
        }
    };

    protected _deleteAttachment = async () => {
        if (this.attachment) {
            const deleted = await AttachmentActions.delete(this.attachment);
            if (deleted) this.ok();
            else this.loadData();
        }
    };
    protected async open() {
        if (!this.attachment) return;
        AttachmentActions.download(this.attachment);
    }

    protected openParent = async (model: IStoredObjectModel) => {
        if (model && model.showDialog) {
            await model.showDialog();
            this.loadData();
        }
    };

    protected copy = () => {
        if (!this.attachment) {
            new NotifyUserMessage('attachment.not-found');
            return;
        }

        Utilities.copyToClipboard(getAttachmentPublicUrl(this.attachment));
    };

    unattach = async (item: StoredObject) => {
        if (!this.attachment) return;

        const { cancelled } = await AttachmentActions.unattach(item, this.attachment);

        if (!cancelled) {
            const index = this.attachedToItems.findIndex(i => i._id === item._id);
            if (index > -1) this.attachedToItems.splice(index, 1);
        }
    };
}
