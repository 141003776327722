import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { ReactNode } from 'react';
import useTranslation from '../../ReactUI/hooks/useTranslation';
import { twoFactorActiveSx } from './twoFactorActiveSx';
import { twoFactorInactiveSx } from './twoFactorInactiveSx';

export const TwoFactorListItem = (props: {
    icon: ReactNode;
    title: TranslationKey;
    description: TranslationKey;
    hint: string;
    active: boolean;
    onClick: () => void;
}) => {
    const { icon, title, description, hint, active, onClick } = props;

    const { t } = useTranslation();

    return (
        <ListItem disablePadding>
            <ListItemButton onClick={onClick}>
                <ListItemIcon sx={active ? twoFactorActiveSx : twoFactorInactiveSx}>{icon}</ListItemIcon>
                <ListItemText primary={t(title)} secondary={t(description, { hint })} />
            </ListItemButton>
        </ListItem>
    );
};
