import type { StandardApiResponse, TranslationKey } from '@nexdynamic/squeegee-common';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Api } from '../../Server/Api';

export const disableTwoFactorAuth = async () => {
    try {
        if (
            !(await new Prompt('two-factor.dialog-disable-title', 'two-factor.dialog-disable-description', {
                okLabel: 'two-factor.dialog-disable-yes',
                cancelLabel: 'two-factor.dialog-disable-cancel',
            }).show())
        ) {
            return;
        }

        new LoaderEvent(true, true);
        const deleteResult = await Api.delete<StandardApiResponse>(null, '/api/authentication/multi-factor');

        new LoaderEvent(false);
        if (!deleteResult?.data?.success) {
            const error = deleteResult?.data?.success === false ? deleteResult?.data?.error : 'general.unknown-error';
            await new Prompt('general.error', error as TranslationKey, { cancelLabel: '' }).show();
            return;
        } else {
            new Prompt('two-factor.dialog-disable-success-title', 'two-factor.dialog-disable-success-description', {
                cancelLabel: '',
            }).show();
            return;
        }
    } catch (error) {
        new LoaderEvent(false);
        await new Prompt('general.error', 'general.unknown-error-occurred', { cancelLabel: '' }).show();
        return;
    }
};
