import OffIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import AuthIcon from '@mui/icons-material/Security';
import { Divider, List, Typography } from '@mui/material';
import { DialogPaper, DialogRoot } from '@nexdynamic/nex-ui-react';
import type { MultiFactorAuthInfo, TranslationKey } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import type { AureliaReactComponentDialogComponent } from '../../Dialogs/AureliaReactComponentDialogComponent';
import { Offline } from '../../ReactUI/Offline';
import useTranslation from '../../ReactUI/hooks/useTranslation';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { TwoFactorListItem } from './TwoFactorListItem';
import type { TwoFactorListItemProps } from './TwoFactorListItemProps';
import { disableTwoFactorAuth } from './disableTwoFactorAuth';
import { enableEmailTwoFactor } from './enableEmailTwoFactor';
import { enableSmsTwoFactor } from './enableSmsTwoFactor';
import { enableTotpTwoFactor } from './enableTotpTwoFactor';
import { getCurrentTwoFactor } from './getCurrentTwoFactor';

export const TwoFactorAuthConfig: AureliaReactComponentDialogComponent = () => {
    const { t } = useTranslation();

    const [twoFactor, setTwoFactor] = useState<MultiFactorAuthInfo>({ type: 'none' });
    const [none, setNone] = useState<TwoFactorListItemProps>();
    const [sms, setSms] = useState<TwoFactorListItemProps>();
    const [email, setEmail] = useState<TwoFactorListItemProps>();
    const [totp, setTotp] = useState<TwoFactorListItemProps>();
    const [description, setDescription] = useState<TranslationKey>('empty.string');
    const [hint, setHint] = useState<string>('');

    const refreshTwoFactorMethod = async () => {
        const info = await getCurrentTwoFactor();
        setTwoFactor(info);
        RethinkDbAuthClient.validateSession();
    };

    useEffect(() => void refreshTwoFactorMethod(), []);

    useEffect(() => {
        const { type } = twoFactor;
        const hint = twoFactor.type === 'sms' ? twoFactor.mobileHint : twoFactor.type === 'email' ? twoFactor.emailHint : '';

        const none = type === 'none' ? 'two-factor.dialog-none-description-enabled' : 'two-factor.dialog-none-description-disabled';
        setNone({
            onClick: type === 'none' ? () => undefined : () => disableTwoFactorAuth().then(refreshTwoFactorMethod),
            active: twoFactor.type === 'none',
            icon: <OffIcon />,
            title: 'two-factor.dialog-none-title',
            description: none,
            hint,
        });

        const totp = type === 'totp' ? 'two-factor.dialog-totp-description-enabled' : 'two-factor.dialog-totp-description-disabled';
        setTotp({
            icon: <AuthIcon />,
            title: 'two-factor.dialog-totp-title',
            description: totp,
            onClick: type === 'totp' ? () => undefined : () => enableTotpTwoFactor().then(refreshTwoFactorMethod),
            active: twoFactor.type === 'totp',
            hint,
        });

        const sms = type === 'sms' ? 'two-factor.dialog-sms-description-enabled' : 'two-factor.dialog-sms-description-disabled';
        setSms({
            icon: <MessageIcon />,
            title: 'two-factor.dialog-sms-title',
            description: sms,
            onClick: type === 'sms' ? () => undefined : () => enableSmsTwoFactor().then(refreshTwoFactorMethod),
            active: twoFactor.type === 'sms',
            hint,
        });

        const email = type === 'email' ? 'two-factor.dialog-email-description-enabled' : 'two-factor.dialog-email-description-disabled';
        setEmail({
            icon: <EmailIcon />,
            title: 'two-factor.dialog-email-title',
            description: email,
            onClick: type === 'email' ? () => undefined : () => enableEmailTwoFactor().then(refreshTwoFactorMethod),
            active: twoFactor.type === 'email',
            hint,
        });

        switch (twoFactor.type) {
            case 'none':
                setDescription(none);
                setHint('');
                break;
            case 'totp':
                setDescription(totp);
                setHint('');
                break;
            case 'sms':
                setDescription(sms);
                setHint(twoFactor.mobileHint);
                break;
            case 'email':
                setDescription(email);
                setHint(twoFactor.emailHint);
                break;
        }
    }, [twoFactor]);

    if (!Api.isConnected) return <Offline />;

    return (
        <DialogRoot>
            <Typography variant='h6'>{t(description, { hint })}</Typography>
            <DialogPaper>
                <List sx={{ p: 0 }}>
                    {none && <TwoFactorListItem {...none} />}
                    <Divider />
                    {totp && <TwoFactorListItem {...totp} />}
                    {sms && <TwoFactorListItem {...sms} />}
                    {email && <TwoFactorListItem {...email} />}
                </List>
            </DialogPaper>
        </DialogRoot>
    );
};
