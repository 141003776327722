import type { ICustomerSummary, InvoiceTransactionSummary, TranslationKey } from '@nexdynamic/squeegee-common';
import type { PaymentMethod } from '@stripe/stripe-js';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';
import { SubscriptionApi } from '../Server/SubscriptionApi';

//TODO sort out stripe types create our own and move these into common
export type PaymentDetails = PaymentMethod;

export type PlanInterval = 'month' | 'year';
export type PlanPeriod = {
    title: string;
    total: number;
    additional: number;
    currency: string;
    planId: string;
    interval: PlanInterval;
    hasVat: boolean;
};

type GetInvoiceSummariesParams = {
    limit?: number;
    startDate?: string;
    endDate?: string;
};

export type Plan = {
    name: string;
    periods: Array<PlanPeriod>;
    trialPeriod?: number;
    planLevel?: 'core' | 'advanced' | 'ultimate' | 'infinite';
};

export class AccountBillingService {
    public static async getAccount(): Promise<ICustomerSummary | undefined> {
        try {
            const result = await Api.get<ICustomerSummary>(null, '/api/subscriptions/customer');
            return result?.data;
        } catch (error) {
            Logger.error('Unexpected error fetching customer account', error);
        }
    }

    public static async getPaymentDetails(): Promise<PaymentDetails | undefined> {
        try {
            const paymentDetails = await Api.get<PaymentDetails>(null, '/api/payment-details');
            return paymentDetails?.data || undefined;
        } catch (error) {
            Logger.error('Unable to get account payment details', error);
        }
    }

    public static async getSelectablePlans(): Promise<Array<Plan> | undefined> {
        try {
            const plans = await SubscriptionApi.getPlans();
            if (!plans) return;
            const selectablePlans: Record<string, Plan> = {};

            const monthlyPlans = plans.filter(p => p.interval === 'month');

            for (const plan of monthlyPlans) {
                const planName = plan.id.split('-')[1];

                if (!selectablePlans[planName]) {
                    selectablePlans[planName] = {
                        planLevel: planName.toLowerCase() as 'core' | 'advanced' | 'ultimate',
                        name: ApplicationState.localise(`account.${planName.toLocaleLowerCase()}` as TranslationKey),
                        trialPeriod: plan.trial_period_days || undefined,
                        periods: plans
                            .filter(p => p.id.indexOf(planName) > -1)
                            .map(p => {
                                return {
                                    planId: p.id,
                                    total: (p.amount || 0) / 100,
                                    additional: p.additionalAmount ? (p.additionalAmount || 0) / 100 : 0,
                                    currency: p.metadata.currencySymbol,
                                    interval: p.interval,
                                    title:
                                        p.interval === 'year'
                                            ? ApplicationState.localise('account.billing-yearly')
                                            : ApplicationState.localise('account.billing-monthly'),
                                    hasVat: !!ApplicationState.account.language?.endsWith('GB')
                                };
                            }),
                    };
                }
            }

            return Object.keys(selectablePlans)
                .map(key => selectablePlans[key])
                .reverse();
        } catch (error) {
            Logger.error('Unable to get selectable plans', error);
        }
    }

    public static async getInvoiceSummaries(params?: GetInvoiceSummariesParams): Promise<Array<InvoiceTransactionSummary> | undefined> {
        try {
            const result = await Api.get<Array<InvoiceTransactionSummary>>(
                null,
                '/api/user/invoices',
                false,
                undefined,
                undefined,
                undefined,
                { params }
            );
            if (result?.status === 500) {
                throw 'Error Getting Invoices from Server';
            }
            return result?.data;
        } catch (error) {
            Logger.error('Unable to get stripe invoices', error);
        }
    }
}
