import type { Alert } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { SqueegeeEvent } from '../Events/SqueegeeEvent';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
export class AlertCountUpdateEvent extends SqueegeeEvent {
    public count: number;
    constructor() {
        super();
        this.count = AlertCountUpdateEvent.getCount();
    }
    public static getCount(): number {
        const isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);
        const email = RethinkDbAuthClient.session?.email;
        if (!email) return 0;

        return Data.count<Alert>('alerts', alert => {
            // Not an admin and not in the audience
            if (!isOwnerAdminOrCreator && !alert.audience?.includes(email)) return false;

            // Is an admin, there is an audience and the user is not in the audience.
            if (isOwnerAdminOrCreator && alert.audience?.length && !alert.audience.includes(email)) return false;

            return true;
        });
    }
}
