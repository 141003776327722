// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-on-file {
  padding: 16px;
  font-weight: 500;
  display: flex;
}
.card-on-file__card-brand {
  max-height: 42px;
  max-width: 60px;
  margin-right: 16px;
}
.card-on-file__card-brand img {
  height: 42px;
}
.card-on-file__number {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Account/Components/card-on-file.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,aAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,kBAAA;AACR;AAAQ;EACI,YAAA;AAEZ;AACI;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;AACR","sourcesContent":[".card-on-file {\n    padding: 16px;\n    font-weight: 500;\n    display: flex;\n\n    &__card-brand {\n        max-height: 42px;\n        max-width: 60px;\n        margin-right: 16px;\n        img {\n            height: 42px;\n        }\n    }\n    &__number {\n        font-size: 18px;\n        font-weight: bold;\n        margin-bottom: 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
