import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { PLATFORM } from 'aurelia-framework';
import type { IRoute } from '../IRoute';

export const routes: Array<IRoute> = [
    {
        route: '',
        name: '',
        title: 'route-config.account-overview-title',
        moduleId: PLATFORM.moduleName('Account/Views/AccountOverview'),
        nav: false,
        settings: {
            roles: ['Owner'],
        },
    },
    {
        route: 'billing-history',
        name: '',
        title: 'route-config.account-invoices-title',
        moduleId: PLATFORM.moduleName('Account/Views/AccountBillingHistory'),
        nav: false,
        settings: {
            roles: ['Owner'],
        },
    },
    {
        route: 'billing',
        name: '',
        title: 'route-config.account-billing-title',
        moduleId: PLATFORM.moduleName('Account/Views/AccountBilling'),
        nav: false,
        settings: {
            roles: ['Owner'],
        },
    },

    {
        route: 'subscription',
        name: 'account-subscription',
        title: 'route-config.account-subscription-title',
        moduleId: PLATFORM.moduleName('Account/Views/Subscription'),
        nav: false,
        settings: {
            roles: ['Owner'],
        },
    },
    {
        route: 'profile/two-factor-auth',
        name: 'account-two-factor-auth',
        title: 'route-config.account-two-factor-auth-title' as TranslationKey,
        moduleId: PLATFORM.moduleName('Account/Views/TwoFactor'),
        nav: false,
        settings: {
            roles: ['Owner'],
        },
    }
];
