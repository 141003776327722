import type { InvoiceTransactionSummary } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { AccountActions } from '../../Account/AccountActions';

export class AccountInvoiceCustomElement {
    @bindable invoiceSummary?: InvoiceTransactionSummary;
    download() {
        if (this.invoiceSummary) {
            AccountActions.viewInvoiceSummary(this.invoiceSummary);
        }
    }

    @computedFrom('invoiceSummary.items.length')
    get description() {
        if (!this.invoiceSummary) return;
        return this.invoiceSummary.items?.map(i => i.description).join(', ');
    }
}
