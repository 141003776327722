import { bindable, computedFrom } from 'aurelia-framework';
import type { PlanPeriod } from '../../Account/AccountBillingService';
import './plan-period.scss';
export class PlanPeriodCustomElement {
    @bindable period: PlanPeriod;

    @computedFrom('period.amount')
    get price() {
        if (!this.period) return;
        return `${this.period.currency}${this.period.total.toFixed(2)}`;
    }
}
