import type { ISession } from '@nexdynamic/squeegee-common';
import Axios from 'axios';
import { getHeaders } from '../../Server/getHeaders';


export const getSecondFactorClient = (session: ISession) => {
    const client = Axios.create({ withCredentials: true });
    client.defaults.headers = getHeaders(session);
    return client;
};
